import React from 'react';
import { useTranslate } from '@tolgee/react';
import SectionContainer from '../../Molecules/SectionContainer';
import { HomeSectionProps } from '../HeroSection';
import { getDirectusImageUrl } from '@/utils/getUrl';

export interface NetherexSectionProps {}

const NetherexSection: React.FunctionComponent<HomeSectionProps> = (props) => {
  const { t } = useTranslate('newHome');

  return (
    <SectionContainer
      title={t(props.title)}
      description={t(props.description)}
      backgroundIosVideo={getDirectusImageUrl(props.bgIosVideo)}
      backgroundVideo={getDirectusImageUrl(props.bgVideo)}
      contentPosition='left'
      imageUrl={getDirectusImageUrl(props.image)}
      hasAnimation={false}
      imagePosition='bottom'
      descColor='#ECE7BE'
      details={props?.details?.map((image) => getDirectusImageUrl(image.imageId))}
      detailBorderImage='/images/new-home/netherex-border.png'
      titleStyle={{
        background: 'linear-gradient(90deg, #999855, #B17A3C)' /* Adjust the colors and angle as needed */,
        WebkitTextFillColor: 'transparent',
        WebkitBackgroundClip: 'text',
        backgroundClip: 'text',
        textFillColor: 'transparent',
      }}
      imageClasses='bottom-0 h-full'
    />
  );
};

export default NetherexSection;
