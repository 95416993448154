import { Typography } from '@/components/Atoms';
import SectionContainer from '@/components/Molecules/SectionContainer';
import Link from 'next/link';
import React from 'react';
import { HomeDetailDataType } from '../../HeroSection';
import { useTranslate } from '@tolgee/react';

export interface BattleOfRoversSectionProps {
  data?: HomeDetailDataType;
}

const BattleOfRoversSection: React.FunctionComponent<BattleOfRoversSectionProps> = ({ data }) => {
  const { t } = useTranslate();
  if (!data) return null;
  return (
    <SectionContainer
      mainContent={
        <div className='flex flex-col lg:w-1/2 gap-2 lg:gap-4 p-4 xl:p-6 rounded-lg'>
          <div>
            {data?.title?.map((title) => (
              <Typography
                isBold
                key={title.text}
                size='s40ToS24'
                className='text-shadow-lg'
                style={{
                  color: title?.color || 'white',
                }}
                Component='h1'
              >
                {t(title?.text)}
              </Typography>
            ))}
            {data?.description?.map((description) => (
              <Typography
                size='s28ToS18'
                className='text-shadow-lg'
                style={{
                  color: description?.color || 'white',
                }}
              >
                {t(description?.text)}
              </Typography>
            ))}
          </div>
          <div className='flex gap-1'>
            <Link href={data.button[0].link}>
              <div
                className='px-4 py-1 lg:px-4 lg:py-2 rounded-[4px] md:rounded-lg cursor-pointer'
                style={{
                  background: data.button[0].bg_color,
                  color: data.button[0].text_color,
                }}
              >
                <Typography isBold size='s22ToS14'>
                  {t(data.button[0].text)}
                </Typography>
              </div>
            </Link>
          </div>
        </div>
      }
      contentPosition='left'
      backgroundVideo='https://landrockercdn.s3.eu-central-1.amazonaws.com/landrocker_pro_website/BattleOfRovers/BOR.mp4'
      height='85vh'
    />
  );
};

export default BattleOfRoversSection;
