import { Typography } from '@/components/Atoms';
import SectionContainer from '@/components/Molecules/SectionContainer';
import dynamic from 'next/dynamic';
import Link from 'next/link';
import React from 'react';
import { HomeDetailDataType } from '../../HeroSection';
import { getDirectusImageUrl } from '@/utils/getUrl';
import { useTranslate } from '@tolgee/react';

export interface CraftSectionProps {
  data?: HomeDetailDataType;
  imageId?: string;
  mobileImageId?: string;
}

const DynamicBannerCountdown = dynamic(() => import('@/components/Builder/CommandCenterBanner/BannerCountdown'), { ssr: false });

const CraftSection: React.FunctionComponent<CraftSectionProps> = ({ data, imageId, mobileImageId }) => {
    const { t } = useTranslate();
  
  if (!data) return null;
  const { title, subtitle, description, button, countdown } = data;
  return (
    <SectionContainer
      mainContent={
        <div className='flex flex-col h-full gap-2 p-4 rounded-lg lg:gap-4 xl:p-6'>
          {/* Title */}
          <div>
            {title?.map((item, index) => (
              <Typography
                key={index}
                style={{ textShadow: '2px 2px 4px black', color: item.color || 'white', padding: "0 0 5px 0" }}
                isBold
                size='s40ToS24'
                className={`text-shadow-lg text-center lg:text-start lg:leading-9`}
                Component={index === 0 ? 'h1' : 'h2'}
              >
                {t(item.text)}
              </Typography>
            ))}
          </div>

          {/* Subtitle */}
          {subtitle && (
            <Typography
              style={{ textShadow: '2px 2px 4px black' }}
              Component={'p'}
              size='s22ToS16'
              className='gap-1 font-semibold text-center text-shadow-lg lg:text-start'
            >
              {subtitle.map((item, index) => (
                <div key={index} className='' style={{ color: item?.color }}>
                  {' '}
                  {t(item.text)}
                </div>
              ))}
            </Typography>
          )}

          {/* Description */}
          <div className='lg:my-8'>
            {description?.map((item, index) => (
              <Typography
                key={index}
                style={{ textShadow: '2px 2px 4px black' }}
                Component={'p'}
                size={index === 0 ? 's20ToS14' : 's28ToS18'}
                className={`${index === 0 ? '' : 'font-bold'} text-center lg:text-start text-shadow-lg`}
              >
                <span className={`text-${item.color || 'white'}`}>{t(item.text)}</span>
              </Typography>
            ))}

            {/* Button */}
            {button?.[0] && (
              <div className='flex justify-center lg:justify-start'>
                <Link href={button[0].link || '#'} passHref>
                  <a>
                    <div
                      className={`mt-2 px-4 py-2 font-bold rounded-lg cursor-pointer w-fit`}
                      style={{
                        backgroundColor: button[0].bg_color || '#F3BA2F',
                        color: button[0].text_color || 'black',
                      }}
                    >
                      <Typography size='s22ToS16'>{t(button[0].text)}</Typography>
                    </div>
                  </a>
                </Link>
              </div>
            )}
          </div>

          {/* Countdown */}
          {countdown && (
            <div className='xl:mx-auto'>
              <DynamicBannerCountdown targetTime={new Date(countdown)} mode={'end'} hasLabel={false} showCountdown={true} size='large' />
            </div>
          )}
        </div>
      }
      contentPosition='left'
      backgroundImage={getDirectusImageUrl(imageId)}
      mobileImage={getDirectusImageUrl(mobileImageId)}
      height='85vh'
    />
  );
};

export default CraftSection;
