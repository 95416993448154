import React, { useEffect, useState } from 'react';
import { isIOS } from 'react-device-detect';
import classNames from 'classnames';
import { Typography } from '@/components/Atoms';
import styles from './index.module.scss';
import { getDirectusImageUrl } from '@/utils/getUrl';
import { useTranslate } from '@tolgee/react';

export interface HomeGifCardProps {
  title?: string;
  description?: string;
  imageId?: string;
  videoId?: string;
  tags?: Array<string>;
  className?: string;
}

const HomeGifCard: React.FunctionComponent<HomeGifCardProps> = ({ description, title, imageId, tags, videoId, className }) => {
  const { t } = useTranslate();
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  useEffect(() => {
    setTimeout(() => {
      setIsVideoLoaded(true);
    }, 1000);
  }, []);
  return (
    <div className={classNames(styles.card, 'max-w-[320px] xl:max-w-[400px]', className)}>
      <div className={styles.icon}>
        {!isIOS ? (
          isVideoLoaded && (
            <video
              preload='none'
              autoPlay
              loop
              muted
              playsInline
              className={styles.icon}
              src={getDirectusImageUrl(videoId)}
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: '10px',
              }}
            />
          )
        ) : (
          <img src={getDirectusImageUrl(imageId)} alt={`${title} ${styles.icon}`} />
        )}
      </div>
      <Typography size='s26ToS16' className='font-bold'>
        {t(title)}
      </Typography>
      <div className={styles['button-group']}>
        {tags?.length > 0 &&
          tags.map((tag, index) => (
            <button key={index} className={styles['card-button']}>
              {tag}
            </button>
          ))}
      </div>
      <Typography Component={'p'} size='s18ToS14' className='text-[#C9C5CA] mt-5'>
        {t(description)}
      </Typography>
    </div>
  );
};

export default HomeGifCard;
