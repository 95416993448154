import {
  Basket,
  CompanionSvg,
  EconomySvg,
  Financial,
  NewFuelSvg,
  PlayIconSvg,
  PrimaryWeapon,
  Roadmap,
  RoversSvg,
  SecondaryWeapon,
  TasksSvg,
} from '@/components/Svg';
import HomeIcon from '@/components/Svg/Home';
import BlogSvg from '@/components/Svg/Updates/BlogSvg';
import BreakingNewsSvg from '@/components/Svg/Updates/BreakingNewsSvg';
import NewsSvg from '@/components/Svg/Updates/NewsSvg';
import PressSvg from '@/components/Svg/Updates/PressSvg';

export { default } from './Header';

export const MENU_LINKS: Array<{
  label: string;
  labelFallback?: string;
  href?: string;
  icon?: any;
  badge?: string;
  subLinks?: Array<{
    icon?: any;
    label: string;
    href: string;
    description: string;
    disabled?: boolean;
  }>;
}> = [
  {
    label: 'home', // Translation key for Home
    href: '/',
    icon: HomeIcon,
  },
  {
    label: 'playTitle', // Translation key for Play Title
    labelFallback: 'Games',
    href: '/start-mining',
    icon: PlayIconSvg,
    badge: 'Beta',
  },
  {
    label: 'cc', // Translation key for Command Center
    labelFallback: 'Command Center',
    href: '/command-center',
    icon: TasksSvg,
    badge: 'Beta',
  },
  {
    label: 'marketTitle', // Translation key for Marketplace Title
    labelFallback: 'Marketplace',
    href: 'https://marketplace.landrocker.io/',
    icon: Basket,
    // subLinks: [
    //   {
    //     label: 'marketRoverTitle', // Translation key for Rover Title
    //     href: '/marketplace?assetType=Rover',
    //     description: 'marketRoverDesc', // Translation key for Rover Description
    //     icon: RoversSvg,
    //   },
    //   {
    //     label: 'marketTacticalTitle', // Translation key for Tactical Tools Title
    //     href: '/marketplace?assetType=TacticalTools',
    //     description: 'marketTacticalDesc', // Translation key for Tactical Tools Description
    //     icon: SecondaryWeapon,
    //   },
    //   {
    //     label: 'marketFuelTitle', // Translation key for Fuel Title
    //     href: '/marketplace?assetType=Fuel',
    //     description: 'marketFuelDesc', // Translation key for Fuel Description
    //     icon: NewFuelSvg,
    //   },
    //   {
    //     label: 'marketCompanionTitle', // Translation key for Companion Title
    //     href: '/marketplace?assetType=Companion',
    //     description: 'marketCompanionDesc', // Translation key for Companion Description
    //     icon: CompanionSvg,
    //   },
    //   {
    //     label: 'marketWeaponTitle', // Translation key for Weapon Title
    //     href: '/marketplace?assetType=Weapon',
    //     description: 'marketWeaponDesc', // Translation key for Weapon Description
    //     icon: PrimaryWeapon,
    //   },
    // ],
  },

  {
    label: 'updateTitle', // Translation key for Updates Title
    labelFallback: 'Updates',
    subLinks: [
      {
        label: 'updateNewsTitle', // Translation key for News Title
        description: 'updateNewsDesc', // Translation key for News Description
        href: '/news',
        icon: NewsSvg,
      },
      {
        label: 'updateBlogTitle', // Translation key for Blog Title
        description: 'updateBlogDesc', // Translation key for Blog Description
        href: '/blogs',
        icon: BlogSvg,
      },
      {
        label: 'updateBreakingNewsTitle', // Translation key for Breaking News Title
        description: 'updateBreakingNewsDesc', // Translation key for Breaking News Description
        href: '/breaking-news',
        icon: BreakingNewsSvg,
      },
      {
        label: 'updatePressTitle', // Translation key for Press Title
        description: 'updatePressDesc', // Translation key for Press Description
        href: '/press',
        icon: PressSvg,
      },
      {
        label: 'roadmap', // Translation key for Roadmap
        description: 'Discover upcoming features and plans for LandRocker.', // Translation key for Press Description
        href: '/roadmap',
        icon: Roadmap,
      },
    ],
  },
  {
    label: 'defi', // Translation key for Updates Title
    labelFallback: 'DeFi',
    subLinks: [
      {
        label: 'staking', // Translation key for Staking
        description: 'stakingDesc',
        href: '/stake',
        icon: Financial,
      },
      {
        label: 'economy', // Translation key for Economy
        description: 'economyDesc',
        href: '/how-to-play-mine-earn',
        icon: EconomySvg,
      },
    ],
  },

  // {
  //   label: 'inventory', // Translation key for Inventory
  //   href: '/inventory',
  // },

  {
    label: 'events', // Translation key for Inventory
    labelFallback: 'Events',
    href: '/events',
  },
];
