import { ReactNode, useEffect, useState } from 'react';
import { Tooltip } from '@material-tailwind/react';
import { useTranslate } from '@tolgee/react';
import { Typography } from '@/components/Atoms';
import { allMaterials } from '@/utils/Static/AllMaterials';

interface IconCountProps {
  imageSrc?: string;
  count: number;
  isActive: boolean;
  currency?: string;
  icon?: ReactNode;
  typeId?: string;
  type?: string;
  title?: string;
}

const IconCount: React.FunctionComponent<IconCountProps> = (props: { imageSrc; count; currency; isActive; icon; typeId; type; title }) => {
  const { count, currency, icon, imageSrc, isActive, typeId, type, title } = props;
  const [elementDetail, setElementDetail] = useState({ Abbr: '', Unit: '', Icon: '' });
  const { t } = useTranslate('commandCenter');

  useEffect(() => {
    if (typeId && type === 'material') {
      setElementDetail(allMaterials.find((item) => item.Id === typeId));
    }
  }, [typeId, type]);

  return (
    <Tooltip
      // @ts-ignore
      content={`${t('tooltips.' + type)}`}
      animate={{
        mount: { scale: 1, y: 0 },
        unmount: { scale: 0, y: 25 },
      }}
      placeholder=''
      onPointerEnterCapture={undefined}
      onPointerLeaveCapture={undefined}
    >
      <div className='flex items-center gap-2 z-30 w-f ull col-span-1'>
        {icon && <div className='w-12 h-12'>{icon}</div>}
        {imageSrc && (
          <div className='w-12 h-12'>
            <img src={imageSrc} className='w-full h-full object-contain' alt='image not loaded.' />{' '}
          </div>
        )}
        <Typography size='s12ToS12' className={isActive ? 'text-white' : 'text-[#8E8E8E]'}>
          {type === 'material' && elementDetail?.Abbr} + {count} {currency ?? ''} {type === 'material' && elementDetail?.Unit} {title}
        </Typography>
      </div>
    </Tooltip>
  );
};

export default IconCount;
