/* eslint-disable import/no-anonymous-default-export */
import { IExplore } from '@/types/DTO/marketplace';
import { IBuyProps, IGetClaimModalData, IGetUserBlueprint, IHistoryProps, OtherAccountsResponseType } from '@/types/Inventory';
import { axiosInstanceTwo } from '../serviceTest';

export default {
  inventory: (token?: string) =>
    axiosInstanceTwo.get<IExplore>('/api/get/user/inventory', {
      headers: {
        token,
      },
    }),
  inventoryV2: (token?: string) =>
    axiosInstanceTwo.get<IExplore>('/api/get/user/inventory/v2', {
      headers: {
        token,
      },
    }),
  lootboxReveal: (id: string) => axiosInstanceTwo.post(`/api/asset/lootbox/revile?id=${id}`),
  getInventoryHistory: () => axiosInstanceTwo.get<IHistoryProps>('/api/materials/get/my/sell/orders'),
  getClaimModalData: (amount: number) => axiosInstanceTwo.get<IGetClaimModalData>(`api/user/stake/conf/reward?amount=${amount}`),
  getBuyList: () => axiosInstanceTwo.get<IBuyProps>('/api/materials/get/sell/orders'),
  sendBuyElement: (order_id: string) => axiosInstanceTwo.get<any>(`/api/materials/buy/orders?order_id=${order_id}`),
  checkClaim: (wallet: string) => axiosInstanceTwo.get<any>(`/api/user/check/validate/claim/reward?wallet=${wallet}`),
  getCreateSaleList: () => axiosInstanceTwo.get<IHistoryProps>('/api/materials/get/my/sell/order'),
  getUserBlueprint: () => axiosInstanceTwo.get<IGetUserBlueprint>('/api/recipe/get/user/recipe'),
  cancelledCreateSale: (reserve_id: string) => axiosInstanceTwo.get<any>(`/api/materials/cancel/sell/order?reserve_id=${reserve_id}`),
  createSaleRequest: (material_id: string, material_amount: number, material_price: number) =>
    axiosInstanceTwo.get<any>(
      `/api/materials/set/sell/order?material_id=${material_id}&material_amount=${material_amount}&material_price=${material_price}`,
    ),
  getPurchaseHistory: (params: { q?: string; order?: string; order_action?: string }) =>
    axiosInstanceTwo.get<IHistoryProps>('/user/get/referral/history', {
      params,
    }),
  // checkUserFollowStatus: () => axiosInstanceTwo.get<any>('/api'),
  sendUserTelId: (data: any) => axiosInstanceTwo.post('/api/v1/users/telegram/check-telegram-user', data, { baseURL: 'https://file.landrocker.io' }),
  getTotalLrt: () => axiosInstanceTwo.get('/api/user/get/mining/lrt/reward/web'),
  getCosmaAmount: () => axiosInstanceTwo.get('/api/user/get/second/fuel'),
  checkOtherAccount: () => axiosInstanceTwo.get<OtherAccountsResponseType>('/api/user/get/other/acoount'),
  getUserDataInventory: (token?: string) =>
    axiosInstanceTwo.get('/api/minigame/get/user/data', {
      headers: {
        token,
      },
    }),

  removeWallet: (params: { wallet: string }) =>
    axiosInstanceTwo.get('/api/user/remove/wallet', {
      params,
    }),

  allWallet: () => axiosInstanceTwo.get('/api/user/get/all/wallet'),
};
