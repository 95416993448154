import React from 'react';
import { useTranslate } from '@tolgee/react';
import SectionContainer from '../../Molecules/SectionContainer';
import { HomeSectionProps } from '../HeroSection';
import { getDirectusImageUrl } from '@/utils/getUrl';

export interface EtheriansSectionProps {}

const EtheriansSection: React.FunctionComponent<HomeSectionProps> = (props) => {
  const { t } = useTranslate('newHome');

  return (
    <SectionContainer
      title={t(props.title)}
      description={t(props.description)}
      titleColor='#2AADFF'
      descColor='#D0DFE4'
      backgroundIosVideo={getDirectusImageUrl(props.bgIosVideo)}
      backgroundVideo={getDirectusImageUrl(props.bgVideo)}
      imageUrl={getDirectusImageUrl(props.image)}
      contentPosition='right'
      hasAnimation={false}
      imagePosition='bottom'
      details={props?.details?.map((image) => getDirectusImageUrl(image.imageId))}
      detailBorderImage='/images/new-home/etherian-border.png'
      titleStyle={{
        background: 'linear-gradient(90deg, #E0D6D5, #7B9DA8)' /* Adjust the colors and angle as needed */,
        WebkitTextFillColor: 'transparent',
        WebkitBackgroundClip: 'text',
        backgroundClip: 'text',
        textFillColor: 'transparent',
      }}
      imageClasses='bottom-0 h-full'
    />
  );
};

export default EtheriansSection;
