import React, { useRef } from 'react';
import GameSection from './GameSection';
import { HomeSectionProps } from '../HeroSection';
import { getDirectusImageUrl } from '@/utils/getUrl';
import { useTranslate } from '@tolgee/react';

const GameModeSection: React.FunctionComponent<HomeSectionProps> = (props) => {
  const sectionRef = useRef(null);
  const { t } = useTranslate();
  return (
    <div ref={sectionRef} className='px-4 sm:px-7  md:px-10 lg:px-16 xl:px-20 bg-[#000000]'>
      <div className=' md:overflow-x-auto lg:overflow-x-clip md:flex-row flex-col justify-between gap-2 lg:gap-5'>
        <GameSection
          name={t(props.title)}
          title={t(props.title)}
          subtitle={t(props.subtitle)}
          description={t(props.description)}
          buttonText={t(props.nameButtonOne)}
          buttonLink={props.linkButtonOne}
          video={getDirectusImageUrl(props.video)}
          weapons={props.details}
          textColor='#E25709'
        />
      </div>
    </div>
  );
};

export default GameModeSection;
