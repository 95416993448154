import React from 'react';
import { Header, RoadMapItems } from '@/components/GameRoadMap';
import { HomeSectionProps } from '@/components/NewHome/HeroSection';

const RoadmapLayout = (props: HomeSectionProps) => {
  return (
    <div className='w-full flex flex-col items-center mt-10 mb-16 lg:mt-14 lg:mb-20'>
      <Header {...props} />
      <RoadMapItems isHomePage {...props} />
    </div>
  );
};

export default RoadmapLayout;
