import React from 'react';
import { useTranslate } from '@tolgee/react';
import { Typography } from '@/components/Atoms';
import { useResponsive } from '@/hooks';
import SectionContainer from '@/components/Molecules/SectionContainer';
import { HomeSectionProps } from '../HeroSection';
import { getDirectusImageUrl } from '@/utils/getUrl';

export interface HomeLastSectionProps {}

const HomeLastSection: React.FunctionComponent<HomeSectionProps> = (props) => {
  const { t } = useTranslate('newHome');
  const { isDesktop1024 } = useResponsive();

  return (
    <SectionContainer
      backgroundImage={getDirectusImageUrl(props.backgroundImage)}
      mobileImage={getDirectusImageUrl(props.bgMob)}
      contentPosition='right'
      hasAnimation={false}
      descColor='#ECE7BE'
      titleStyle={{
        background: 'linear-gradient(90deg, #999855, #B17A3C)' /* Adjust the colors and angle as needed */,
        WebkitTextFillColor: 'transparent',
        WebkitBackgroundClip: 'text',
        backgroundClip: 'text',
        textFillColor: 'transparent',
      }}
      secondContent={
        <div className='flex flex-col gap-y-6 pt-24 md:pt-0 py-20 lg:py-28'>
          <div>
            <Typography className='leading-4' size='s28ToS18'>
              {t(props.title)}
            </Typography>
            <Typography Component={'h3'} size='s40ToS24' className='font-bold'>
              {t(props.subtitle)}
            </Typography>
          </div>
          {props?.details?.map((item) => (
            <div key={item.title}>
              <Typography Component={'h3'} size='s28ToS18' className='font-bold'>
                {t(item.title)}
              </Typography>
              <Typography className='text-[#C9C5CA]' size='s18ToS14'>
                {t(item.description)}
              </Typography>
            </div>
          ))}
        </div>
      }
      backgroundPosition={isDesktop1024 ? 'top' : 'left'}
    />
  );
};

export default HomeLastSection;
