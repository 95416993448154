/* eslint-disable sonarjs/cognitive-complexity */
import useAuthStatuses from '@/hooks/authentication/useAuthStatuses';
import { useStore } from '@/zustand/store/store';
import { useRouter } from 'next/router';

const OnBoardingCommandCenterModal = (props: { rewardSection; claimButton; state; requirementSection }) => {
  const { rewardSection, claimButton, state, requirementSection } = props;
  // let intro;
  // if (typeof window !== 'undefined') {
  //   intro = introJs.tour(); // Create a single instance of introJs
  // }
  const { onBoardingData, setOnBoardingData } = useStore();
  const router = useRouter();
  const { isLogin } = useAuthStatuses();
  const { ...updatedQuery } = router.query;

  const stepOne: Partial<any>[] = [
    {
      intro: `<div><p class='font-bold'>Welcome to LandRocker!</p><p>Sign up now to claim your <span class="text-[#fab005] font-bold">500 LRT</span> reward!</p><p>Simply click "Start" to get started.</p></div>`,
      position: 'top',
      tooltipClass: 'customTooltipStyle', // Define a custom class for tooltip styling
      highlightClass: 'customHighlightStyle',
    },
    {
      element: '#signup-button',
      intro: 'To sign up in the Landrocker Universe, just Click "Start"!',
      position: 'right', // Change the position to 'top'
      tooltipClass: 'customTooltipStyle',
      highlightClass: 'customHighlightStyle',
    },
  ];

  const stepTwo: Partial<any>[] = [
    {
      element: '#reward-section',
      intro: 'In this section, you can track the rewards for each command.',
      position: 'bottom',
      tooltipClass: 'customTooltipStyle', // Define a custom class for tooltip styling
      highlightClass: 'customHighlightStyle',
    },
    {
      element: '#command-section',
      intro: "Here, you'll find the mission(s) you need to complete to unlock the rewards for this command.",
      position: 'right', // Change the position to 'top'
      tooltipClass: 'customTooltipStyle',
      highlightClass: 'customHighlightStyle',
    },
    {
      element: '#requirement-section',
      intro: 'Any additional requirements for the command will be listed here.',
      position: 'right', // Ensure the position is 'top' for consistency
      tooltipClass: 'customTooltipStyle',
      highlightClass: 'customHighlightStyle',
    },
    {
      element: '#description-section',
      intro: "Here you can find the description of the command, providing details about what you'll need to do to complete it.",
      position: 'right', // Ensure the position is 'top' for consistency
      tooltipClass: 'customTooltipStyle',
      highlightClass: 'customHighlightStyle',
    },

    {
      element: '#signup-button',
      intro: `Once you’ve completed the command, click "Verify" to confirm.`,
      position: 'right', // Ensure the position is 'top' for consistency
      tooltipClass: 'customTooltipStyle',
      highlightClass: 'customHighlightStyle',
    },
  ];

  const stepThree: Partial<any>[] = [
    {
      element: '#claim-button',
      intro: 'Click the claim button to collect your reward and enjoy the benefits of completing the command!',
      position: 'right', // Ensure the position is 'top' for consistency
      tooltipClass: 'customTooltipStyle',
      highlightClass: 'customHighlightStyle',
    },
  ];

  const stepFour: Partial<any>[] = [
    {
      // element: '#reward-section',
      intro: 'Complete this command to unlock these valuable rewards and boost your progress!',
      position: 'top',
      tooltipClass: 'customTooltipStyle', // Define a custom class for tooltip styling
      highlightClass: 'customHighlightStyle',
    },
    {
      element: '#visit_link-button',
      intro: 'Kick off the command by clicking here, soldier! Your next mission awaits!',
      position: 'right', // Change the position to 'top'
      tooltipClass: 'customTooltipStyle',
      highlightClass: 'customHighlightStyle',
    },
  ];

  const stepFive: Partial<any>[] = [
    {
      element: '#command-section',
      intro:
        '<div>The Command Center follows 5 stages:<p>1. `Start` when you initiate the command.</p><p>2. "Verify" this button lets us know that you have completed the command.</p><p>3. `Processing` while the command is in progress.</p><p>4. `Claim` once the command is complete and your reward is ready.</p><p>5. `Completed` when the command is fully finished and all rewards have been claimed.</p></div>',
      position: 'right',
      tooltipClass: 'customTooltipStyle', // Define a custom class for tooltip styling
      highlightClass: 'customHighlightStyle',
    },
  ];

  const completedFunc = () => {
    setOnBoardingData({
      ...onBoardingData,
      isShowHint: true,
    });
  };

  // useEffect(() => {
  //   if (router.query.taskId === '7a249411-3658-4fd0-b9ae-149cea43b631' && rewardSection.current !== null && !isLogin) {
  //     intro
  //       .setOptions({
  //         steps: stepOne,
  //         exitOnOverlayClick: false, // Prevent closing on outside click
  //         showBullets: true, // Example of another option
  //         disableInteraction: true, // Prevent interaction with the rest of the page
  //         doneLabel: 'Start!',
  //         nextLabel: 'Start',
  //       })

  //       .onComplete(() => {
  //         completedFunc();
  //       })
  //       .start(); // Start the onboarding process
  //   }
  //   if (
  //     router.query.taskId !== undefined &&
  //     router.query.isonboarding == 'step-three' &&
  //     rewardSection.current !== null &&
  //     requirementSection.current !== null
  //   ) {
  //     intro
  //       .setOptions({
  //         steps: stepTwo,
  //         exitOnOverlayClick: false, // Prevent closing on outside click
  //         showBullets: true, // Example of another option
  //         disableInteraction: true, // Prevent interaction with the rest of the page
  //         doneLabel: 'Verify',
  //       })
  //       .onComplete(() => {
  //         completedFunc();
  //       })
  //       .start(); // Start the onboarding process
  //   }
  //   if (router.query.taskId !== undefined && router.query.isonboarding == 'step-four' && claimButton.current !== null) {
  //     intro
  //       .setOptions({
  //         steps: stepThree,
  //         exitOnOverlayClick: false, // Prevent closing on outside click
  //         showBullets: false, // Example of another option
  //         disableInteraction: true, // Prevent interaction with the rest of the page
  //         doneLabel: 'Claim',
  //         hidePrev: true,
  //       })
  //       .onComplete(() => {
  //         setOnBoardingData({
  //           ...onBoardingData,
  //           isShowHintClaimButton: true,
  //         });
  //       })
  //       .start(); // Start the onboarding process
  //   }
  //   if (router.query.taskId !== undefined && router.query.isonboarding == 'step-five' && rewardSection.current !== null && state == 'start') {
  //     intro
  //       .setOptions({
  //         steps: stepFour,
  //         exitOnOverlayClick: false, // Prevent closing on outside click
  //         showBullets: true, // Example of another option
  //         disableInteraction: true, // Prevent interaction with the rest of the page
  //         doneLabel: 'Start!',
  //       })
  //       .onComplete(() => {
  //         completedFunc();
  //       })
  //       .start(); // Start the onboarding process
  //   }

  //   if (
  //     router.query.taskId == 'ad64c150-237b-485e-9304-74c84f23753b' &&
  //     router.query.isonboarding == 'step-five' &&
  //     rewardSection.current !== null &&
  //     state == 'pending'
  //   ) {
  //     intro
  //       .setOptions({
  //         steps: stepFive,
  //         exitOnOverlayClick: false, // Prevent closing on outside click
  //         showBullets: false, // Example of another option
  //         disableInteraction: true, // Prevent interaction with the rest of the page
  //         doneLabel: 'Ok',
  //         hidePrev: true,
  //       })
  //       .onComplete(() => {
  //         // router
  //         //   .replace(
  //         //     {
  //         //       pathname: router.pathname,
  //         //       query: updatedQuery,
  //         //     },
  //         //     undefined,
  //         //     { shallow: true },
  //         //   )
  //         //   .then(() => {
  //         //   });
  //         completedFunc();
  //       })
  //       .start(); // Start the onboarding process
  //   }
  // }, [rewardSection.current, router.query, claimButton.current, state, isLogin, requirementSection]);

  return null;
};

export default OnBoardingCommandCenterModal;
