import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import classNames from 'classnames';
import YoutubeVideoPlayer from '@/components/TokenSale/Header/VideoPlayer/YoutubeVideoPlayer';
import VideosStyles from '../Videos.module.scss';

interface IProps {
  posterUrl: string;
  url: string;
  youtube: any;
  isCustomUrl?: boolean;
  className?: string;
}

const CustomVideo = (props: IProps) => {
  const { posterUrl, url, youtube, isCustomUrl, className } = props;
  const [playing, setPlaying] = useState(false);
  const togglePlay = () => {
    setPlaying(!playing);
  };
  const handleVideoEnd = () => {
    setPlaying(false);
  };
  const backgroundUrl = isCustomUrl ? `${posterUrl}` : `${process.env.NEXT_PUBLIC_STRAPI_MEDIA}/${posterUrl}`;
  return (
    <div className={classNames(VideosStyles.CustomVideoContainer)}>
      {playing || youtube ? (
        false
      ) : (
        <div className={`${VideosStyles.circle}`} onClick={togglePlay}>
          <div className={VideosStyles.triangle} />
        </div>
      )}
      <div className='rounded-lg overflow-hidden'>
        {youtube ? (
          <div className={VideosStyles.CustomVideoContainer}>
            <YoutubeVideoPlayer className={classNames('h-full w-full', className)} url={url} autoPlay={false} />
          </div>
        ) : playing ? (
          <ReactPlayer
            onEnded={handleVideoEnd}
            url={isCustomUrl ? `${url}` : `${process.env.NEXT_PUBLIC_STRAPI_MEDIA}/${url}`}
            playing={playing}
            controls={playing ? true : false}
            width='100%'
            height='100%'
            config={{
              file: {
                attributes: {
                  poster: isCustomUrl ? `${posterUrl}` : `${process.env.NEXT_PUBLIC_STRAPI_MEDIA}/${posterUrl}`,
                },
              },
            }}
          />
        ) : (
          <div
            style={{ backgroundImage: `url(${backgroundUrl})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
            className={`${VideosStyles.CustomVideoContainer}`}
          />
        )}
      </div>
    </div>
  );
};

export default CustomVideo;
