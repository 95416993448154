/* eslint-disable arrow-body-style */
import { useEffect, useState } from 'react';
import { getSession } from 'next-auth/react';
import useSWR, { mutate } from 'swr';
import useSWRInfinite from 'swr/infinite';
import {
  AchievementDetailResponse,
  AllLeaderboardData,
  ClaimRewardResponseType,
  GetCommandersResponse,
  IAffiliateProps,
  IAllTasksResponseType,
  IAllTasksResponseType2,
  ICampaignData,
  IPointsResponse,
  IQueueItem,
  ITaskCategoriesData,
  LeaboardRewardResponseType,
  MyRank,
} from '@/types/Achievements';
import { axiosInstanceTwo } from '../serviceTest';
import axios from 'axios';

const endpoints = Object.freeze({
  getPoints: '/api/user/get/xp',
  getAllTasks: '/user-achievements/',
  getAllTasks2: '/user-achievements/achievement/',
  getAchievementDetailEndpoint: (id: string) => `/user-achievements/achievement/${id}`,
  getCategories: '/user-achievements/category',
  getAchievementTasks: (achievement_id?: number) => {
    const param = `achievement_id=${achievement_id}`;
    return `/api/get/achievement/data?${achievement_id ? param : ''}`;
  },
  getCommanders: '/user-achievements/commander/',
  getAllCommanderAchievements: (id: string) => `/user-achievements/commander/${id}/achievement`,
});

// AchievementTasks
const getAchievementTasks = (achievement_id?: number) => axiosInstanceTwo.get<ICampaignData>(endpoints.getAchievementTasks(achievement_id));
const useAchievementTasks = (achievement_id?: number) =>
  useSWR(endpoints.getAchievementTasks(achievement_id), () => getAchievementTasks(achievement_id));
const mutauteAchievementTasks = (achievement_id?: number) => mutate(endpoints.getAchievementTasks(achievement_id));

const getLeaderBoardInfinit = ({ ...params }) =>
  axiosInstanceTwo.post<ICampaignData>(`/api/achievement/Leaderboard?limit=${params.limit}&offset=${params.offset}`);
const getMyRank = () => axiosInstanceTwo.post<MyRank>('/api/achievement/user/point/data');

const setTaskToQueue = (params: IQueueItem) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { achievement_id, action, task_id, link } = params;
  return axiosInstanceTwo.get(`/api/achievement/set/task?action=${action}&task_id=${task_id}&achievement_id=${achievement_id}&task_link=${link}`);
};
const claimCampaign = (achievementId: number) => axiosInstanceTwo.post(`/api/achievement/claim?achievement_id=${achievementId}`, null);
const getAffiliate = () => axiosInstanceTwo.get<IAffiliateProps>('/api/affiliate/get/records');

const getFilteredAchievement = (args?: GetAllTasks2Payload) => {
  return axiosInstanceTwo.get<IAllTasksResponseType2>(endpoints.getAllTasks2, {
    baseURL: process.env.NEXT_PUBLIC_TASKS_API,
    params: args,
    // paramsSerializer: (params) => {
    //   const parts = [];
    //   for (const key in params) {
    //     if (params.hasOwnProperty(key)) {
    //       const value = params[key];
    //       if (value !== null && value !== undefined) {
    //         if (Array.isArray(value)) {
    //           value.forEach((val) => {
    //             parts.push(`${encodeURIComponent(key)}[]=${encodeURIComponent(val)}`);
    //           });
    //         } else {
    //           parts.push(`${encodeURIComponent(key)}=${encodeURIComponent(value)}`);
    //         }
    //       }
    //     }
    //   }
    //   return parts.join('&');
    // },
  });
};
const getPoints = () => axiosInstanceTwo.get<IPointsResponse>(endpoints.getPoints);
const useGetPoints = () => {
  const [token, setToken] = useState<string>();

  useEffect(() => {
    // const session = await getSession();
    getSession()
      .then((res) => {
        if (res) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setToken(res.accessToken);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return useSWR(token ? endpoints.getPoints : null, getPoints);
};
const mutateGetPoints = () => mutate(endpoints.getPoints);

const claimReward = () => axiosInstanceTwo.get<ClaimRewardResponseType>('/api/point/reward/claim');
const submitAction = ({ taskId, taskLink }: { taskId: number; taskLink: string }) =>
  axiosInstanceTwo.get(`/api/get/achievement/set/task/link?task_id=${taskId}&task_link=${taskLink}`);

const getReferralData = (props: { tab: string }) => axiosInstanceTwo.get(`api/referral/get/user/history?tab=${props.tab}`);
const getArmyCount = () => axiosInstanceTwo.get('api/user/army/count');
const getGlobalReferralData = () => axiosInstanceTwo.get(`api/referral/get/user/history`);
const doneTask = ({
  id,
  data,
  referal_user_id,
  achievement_id,
  token,
}: {
  id: string;
  data: any;
  referal_user_id?: string;
  achievement_id?: string;
  token?: string;
}) =>
  axiosInstanceTwo.post(
    `/user-achievements/action`,
    {
      action_id: id,
      additional_data: data,
      referral_code_commander: referal_user_id ? referal_user_id : '',
      achievement_id,
      // referal_user_id,
    },
    { baseURL: process.env.NEXT_PUBLIC_TASKS_API, headers: { token: token } },
  );

const doneTelegram = ({
  id,
  achievement_id,
  type,
  referal_user_id,
}: {
  id: string;
  achievement_id?: string;
  type: 'landrocker_join' | 'generate_referral_message' | 'generate_referral_network';
  referal_user_id: string;
}) =>
  axiosInstanceTwo.post(
    `/receive`,
    {
      actionId: id,
      achievementId: achievement_id,
      referral_code_commander: referal_user_id ? referal_user_id : '',
      Type: type,
    },
    { baseURL: 'https://referral-bot.landrocker.io' },
    // { baseURL: 'http://172.30.20.75:8880' },
  );
export const getAllTasks = (isTest: boolean) =>
  axiosInstanceTwo.get<IAllTasksResponseType>(`/user-achievements/user/third`, {
    baseURL: process.env.NEXT_PUBLIC_TASKS_API,
    params: isTest
      ? {
          for_test: isTest.toString(),
        }
      : {},
  });

const approveTask = ({ achievement_id }: { achievement_id: string }) => {
  const formData = new FormData();

  formData.append('achievement_id', achievement_id);

  return axiosInstanceTwo.post(`/admin/approve-achievement`, formData, { baseURL: process.env.NEXT_PUBLIC_TASKS_API });
};

const verifyTask = ({ achievement_id, action_id }: { achievement_id: string; action_id: string }) => {
  // const formData = new FormData();

  // formData.append('achievement_id', achievement_id);

  return axiosInstanceTwo.post(`/user-achievements/action/${action_id}/verify`, { achievement_id }, { baseURL: process.env.NEXT_PUBLIC_TASKS_API });
};
const claimAchievement = ({ achievement_id }: { achievement_id: string }) => {
  return axiosInstanceTwo.post(`/user-achievements/achievement/${achievement_id}/claim`, null, { baseURL: process.env.NEXT_PUBLIC_TASKS_API });
};

const claimTask = ({ achievement_id, action_id }: { achievement_id: string; action_id: string }) => {
  const formData = new FormData();

  formData.append('achievement_id', achievement_id);

  return axiosInstanceTwo.post(`/user-achievements/action/${action_id}/claim`, formData, { baseURL: process.env.NEXT_PUBLIC_TASKS_API });
};

export const PAGE_SIZE = 20;
export type GetAllTasks2Payload = {
  commander_id?: string;
  for_test?: boolean;
  per_page?: number;
  page?: number;
  sort?: 'DSC' | 'ASC';
  filter?: string;
  category_ids?: string[];
};
const getAllTasks2 = (args?: GetAllTasks2Payload) => {
  let categories = {};
  args?.category_ids?.forEach((item) => {
    categories = { ...categories, category_ids: item };
  });
  delete args.category_ids;
  const mergedObject = Object.assign({}, args, categories);

  return axiosInstanceTwo.get<IAllTasksResponseType2>(endpoints.getAllTasks2, {
    baseURL: process.env.NEXT_PUBLIC_TASKS_API,
    params: mergedObject,
  });
};

export const getCategories = (args?: GetAllTasks2Payload) =>
  axiosInstanceTwo.get<ITaskCategoriesData>(`/user-achievements/category/`, {
    baseURL: process.env.NEXT_PUBLIC_TASKS_API,
    params: args,
  });
const useAllTasks2Infinite = (args?: GetAllTasks2Payload) =>
  useSWRInfinite(
    (index) => [endpoints.getAllTasks2, { ...args, page: index + 1, per_page: PAGE_SIZE }],
    // eslint-disable-next-line @typescript-eslint/no-shadow
    ([url, args]) => getAllTasks2(args),
    {
      revalidateAll: false,
      revalidateFirstPage: false,
    },
  );

// Commanders
/* *************** */
const CommandersPageSize = 200;

type GetCommandersPayload = {
  page?: number;
  page_size?: number;
  sort_field?: string;
  sort_order?: 'desc' | 'asc';
};

const getCommanders = (args?: GetCommandersPayload) =>
  axiosInstanceTwo.get<GetCommandersResponse>(endpoints.getCommanders, {
    baseURL: process.env.NEXT_PUBLIC_TASKS_API,
    params: args,
  });

type GetCommanderDetailPayload = {
  commander_id?: string;
};
// eslint-disable-next-line sonarjs/no-identical-functions
const getCommanderDetail = (args: GetCommanderDetailPayload) =>
  axiosInstanceTwo.get<GetCommandersResponse>(endpoints.getCommanders, {
    baseURL: process.env.NEXT_PUBLIC_TASKS_API,
    params: args,
  });

const useCommanderDetail = (args?: GetCommanderDetailPayload) =>
  useSWR(args.commander_id ? [endpoints.getCommanders, args] : undefined, () => getCommanderDetail(args));

const useCommandersInfinite = (args?: GetCommandersPayload) =>
  useSWRInfinite(
    (index) => [endpoints.getCommanders, { ...args, page: index + 1, per_page: CommandersPageSize }],
    // eslint-disable-next-line @typescript-eslint/no-shadow
    ([url, args]) => getCommanders(args),
    {
      revalidateAll: false,
      revalidateFirstPage: false,
    },
  );

/* Commander-Achievements */
export type GetAllCommanderAchievementsPayload = GetAllTasks2Payload & {
  commander_id: string;
};

/* AchivementDetail */
/* ********************** */
type GetAchievementDetailPayload = { achievement_id: string };

const getAchievementDetail = (args: GetAchievementDetailPayload) =>
  axiosInstanceTwo.get<AchievementDetailResponse>(endpoints.getAchievementDetailEndpoint(args.achievement_id), {
    baseURL: process.env.NEXT_PUBLIC_TASKS_API,
  });

const useAchievementDetail = (args?: GetAchievementDetailPayload) =>
  useSWR(args.achievement_id ? [endpoints.getAchievementDetailEndpoint(args.achievement_id), args] : undefined, () => getAchievementDetail(args));

const useAllTasks = (isTest: boolean) => useSWR([endpoints.getAllTasks, isTest], () => getAllTasks(isTest));

const useTaskCategories = (args?: GetAllTasks2Payload) =>
  useSWR([endpoints.getCategories, { ...args }], () => getCategories(args), { revalidateOnFocus: false });

const getUserPoints = () => axiosInstanceTwo.post(`/user/get/user/achievement/point/data`);

const getMaterialCommandCenter = () => axiosInstanceTwo.post('api/user/data/materials/command/center');

const getUserTaskRestriction = (id: string) =>
  axiosInstanceTwo.get(`user-achievements/get-asset`, {
    baseURL: process.env.NEXT_PUBLIC_TASKS_API,
    params: {
      action_id: id,
    },
  });

const testAbly = () =>
  axiosInstanceTwo.get(`actions/report/test`, {
    baseURL: process.env.NEXT_PUBLIC_TASKS_API,
  });

const getSelfAchievements = (isTest: boolean) =>
  axiosInstanceTwo.get<IAllTasksResponseType>(`/user-achievements/user/referral`, {
    baseURL: process.env.NEXT_PUBLIC_TASKS_API,
    params: isTest
      ? {
          for_test: isTest.toString(),
        }
      : {},
  });

const useSelfAchievements = (isTest: boolean) => useSWR('/user-achievements/user/referral', () => getSelfAchievements(isTest));

const verifyOtherAccount = (accountType, accountData, type) =>
  // JSON.stringify the accountData if it needs to be sent as a JSON string in URL query
  axiosInstanceTwo.get('/api/user/verify/other/acoount', {
    params: {
      account_type: accountType,
      account_data: accountData,
      type: type,
    },
  });

const objectToQueryParams = (obj: any) => {
  const params = new URLSearchParams();
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      params.append(key, obj[key]);
    }
  }
  return params.toString();
};

const getUserRover = () =>
  axiosInstanceTwo.get(`user-achievements/user/asset`, {
    baseURL: process.env.NEXT_PUBLIC_TASKS_API,
  });

const userDataCommandCenter = () => axiosInstanceTwo.post('api/user/data/command/center');

const getLiteGameResult = (id: string) =>
  axiosInstanceTwo.get(`user-achievements/achievement/${id}/winner`, {
    baseURL: process.env.NEXT_PUBLIC_TASKS_API,
  });

const submitInfluencers = (params: any) => {
  const queryParams = objectToQueryParams(params);
  return axiosInstanceTwo.post(`api/website/set/influencers/join?${queryParams}`);
};

const addAmbassador = (params: any): Promise<any> => {
  return axios.post(`ambassador/register`, params, {
    baseURL: 'https://telegram-app.landrocker.io/',
    headers: {},
  });
};

const getCommandRecentWinners = () =>
  axiosInstanceTwo.get(`user-achievements/last-winners`, {
    baseURL: process.env.NEXT_PUBLIC_TASKS_API,
  });

const getCommandTopWinners = () =>
  axiosInstanceTwo.get(`user-achievements/top-users`, {
    baseURL: process.env.NEXT_PUBLIC_TASKS_API,
  });

const getCommandTopLrtWinners = () =>
  axiosInstanceTwo.get(`user-achievements/top-users/lrt`, {
    baseURL: process.env.NEXT_PUBLIC_TASKS_API,
  });

const getAllLeaderboards = () =>
  axiosInstanceTwo.get<AllLeaderboardData>(`user-achievements/leaderboard`, {
    baseURL: process.env.NEXT_PUBLIC_TASKS_API,
  });

const claimLeaderboardReward = () => axiosInstanceTwo.get<LeaboardRewardResponseType>('api/user/claim/leaderboard/reward');

const api = {
  getUserRover,
  getAchievementTasks,
  submitInfluencers,
  getReferralData,
  getGlobalReferralData,
  useAchievementTasks,
  mutauteAchievementTasks,
  setTaskToQueue,
  claimCampaign,
  getAffiliate,
  getPoints,
  getLeaderBoardInfinit,
  getMyRank,
  claimReward,
  submitAction,
  useGetPoints,
  mutateGetPoints,
  verifyOtherAccount,
  getAllTasks,
  doneTask,
  verifyTask,
  claimAchievement,
  claimTask,
  useAllTasks,
  getUserPoints,
  getUserTaskRestriction,
  useSelfAchievements,
  userDataCommandCenter,
  useTaskCategories,
  getMaterialCommandCenter,
  useAllTasks2Infinite,
  useAchievementDetail,
  getArmyCount,
  approveTask,
  testAbly,
  doneTelegram,
  getCommanders,
  useCommandersInfinite,
  useCommanderDetail,
  getAllTasks2,
  getLiteGameResult,
  getCommandRecentWinners,
  getCommandTopWinners,
  getCommandTopLrtWinners,
  getAllLeaderboards,
  addAmbassador,
  claimLeaderboardReward,
};

export default api;
