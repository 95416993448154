import { StateCreator } from 'zustand';
import { IGetOverallItem } from '@/types/DTO/miniGame';
import { ICommonSlice } from './Interface';

export const commonSlice: StateCreator<ICommonSlice> = (set) => ({
  // state initializing
  isOpenHistoryInventory: false,
  isSoldOut: false,
  visible: false,
  rootStyle: '',
  layoutStyle: '',
  containerStyle: '',
  leaderboardModal: false,
  isNftSuccessModal: {
    open: false,
    selectedCardId: '',
    seen: false,
    nftType: '',
  },
  seasonsVideoPlayerModal: false,
  claimModal: false,
  totalSoldLRT: 0,
  isOpenSellInventory: false,
  isOpenBuyInventory: false,
  isOpenVideo: false,
  isMobileBuyOpen: false,
  isOpenGallery: false,
  isLoading: false,
  isLoadingBalls: false,
  hasAccessForClaim: false,
  isShowToast: {
    isShow: false,
    method: 'default',
    message: '',
  },
  commandCenter: {
    metaTitle: '',
  },
  overalData: {
    craftNFT: 0,
    finishMession: 0,
    fuel: 0,
    LMNT: 0,
    LRT: 0,
    minedBlock: 0,
  },
  notifier: {
    newMail: false,
  },
  UpdateStats: false,
  isTopBannerOpen: false,
  builderActiveSection: null,
  isCreateSaleInventory: false,
  buySectionRef: null,
  newAssetItemInventory: null,
  openConnectLoginOrWalletModal: false,
  collectFuelModal: false,
  transactionHashETH: '',
  showTransactionHashETHModal: {
    isShowModal: false,
    amountPrice: '0',
  },
  seasonZeroVisible: false,
  levelUpData: undefined,
  isShowLevelModal: false,
  isSupportedWebgl: {
    webgl1: false,
    webgl2: false,
  },

  // state update handlers
  setIsOpenHistoryInventory: (isOpenModal: boolean) => {
    set({
      isOpenHistoryInventory: isOpenModal,
    });
  },
  setIsSoldOut: (isSoldOut: boolean) => {
    set({
      isSoldOut: isSoldOut,
    });
  },
  setBuySectionRef: (ref) => {
    set({ buySectionRef: ref });
  },

  setLayoutStyle: (tailwindStyle: string) => {
    set({
      layoutStyle: tailwindStyle,
    });
  },
  setNewAssetItemInventory: (newAssetItemInventory: any) => {
    set({
      newAssetItemInventory: newAssetItemInventory,
    });
  },

  setIsShowLevelModal: (isShowLevelModal: boolean) => {
    set({
      isShowLevelModal: isShowLevelModal,
    });
  },
  setRootStyle: (tailwindStyle: string) => {
    set({
      rootStyle: tailwindStyle,
    });
  },
  setLevelUpData: (levelUpData: string) => {
    set({
      levelUpData: levelUpData,
    });
  },
  setContainerStyle: (tailwindStyle: string) => {
    set({
      containerStyle: tailwindStyle,
    });
  },
  setIsLeaderboardModal: (leaderboardModal: boolean) => {
    set({
      leaderboardModal: leaderboardModal,
    });
  },
  setIsNftSuccessModal: (isNftSuccessModal: { open: boolean; selectedCardId: string; seen: boolean; nftType: string }) => {
    set({
      isNftSuccessModal: {
        open: isNftSuccessModal.open,
        selectedCardId: isNftSuccessModal.selectedCardId,
        seen: isNftSuccessModal.seen,
        nftType: isNftSuccessModal.nftType,
      },
    });
  },
  setSeasonsVideoPlayerModal: (seasonsVideoPlayerModal: boolean) => {
    set({
      seasonsVideoPlayerModal: seasonsVideoPlayerModal,
    });
  },
  setClaimModal: (claimModal: boolean) => {
    set({
      claimModal: claimModal,
    });
  },
  setIsOpenSellInventory: (isOpenModal: boolean) => {
    set({
      isOpenSellInventory: isOpenModal,
    });
  },
  setIsOpenBuyInventory: (isOpenModal: boolean) => {
    set({
      isOpenBuyInventory: isOpenModal,
    });
  },
  setIsOpenVideo: (isOpenModal: boolean) => {
    set({
      isOpenVideo: isOpenModal,
    });
  },
  setIsMobileBuyOpen: (isOpenModal: boolean) => {
    set({
      isMobileBuyOpen: isOpenModal,
    });
  },
  setIsOpenGallery: (isOpenGallery: boolean) => {
    set({
      isOpenGallery: isOpenGallery,
    });
  },
  setIsLoading: (isLoading: boolean) => {
    set({
      isLoading: isLoading,
    });
  },
  setIsLoadingBalls: (isLoadingBalls: boolean) => {
    set({
      isLoadingBalls: isLoadingBalls,
    });
  },
  setIsShowToast: (isShowToast: { method: 'info' | 'success' | 'warning' | 'error' | 'default'; message: string; isShow: boolean }) => {
    set({
      isShowToast: isShowToast,
    });
  },
  setCommandCenter: (commandCenter: { metaTitle: string }) => {
    set({
      commandCenter: { ...commandCenter, metaTitle: commandCenter.metaTitle },
    });
  },
  setOveralData: (overalData: IGetOverallItem) => {
    set({
      overalData: overalData,
    });
  },
  setTotalSoldLRT: (totalSoldLRT: number) => {
    set({
      totalSoldLRT: totalSoldLRT,
    });
  },
  setNotifier: (notifier: any) => {
    set({
      notifier: { newMail: notifier.newMail },
    });
  },
  setUpdateStats: (UpdateStats: boolean) => {
    set({ UpdateStats });
  },
  setIsTopBannerOpen: (isBannerOpen: boolean) => {
    set({ isTopBannerOpen: isBannerOpen });
  },
  setBuilderActiveSection: (builderActiveSection: number) => {
    set({ builderActiveSection });
  },
  setIsCreateSaleInventory: (isOpenModal: boolean) => {
    set({
      isCreateSaleInventory: isOpenModal,
    });
  },
  setOpenConnectLoginOrWalletModal: (openConnectLoginOrWalletModal: boolean) => {
    set({
      openConnectLoginOrWalletModal: openConnectLoginOrWalletModal,
    });
  },
  setCollectFuelModal: (collectFuelModal: boolean) => {
    set({
      collectFuelModal: collectFuelModal,
    });
  },
  setWelcomeModalVisible: (visible: boolean) => {
    set({
      visible: visible,
    });
  },
  setTransactionHashETH: (transactionHashETH: string) => {
    set({
      transactionHashETH: transactionHashETH,
    });
  },
  setShowTransactionHashETHModal: (showTransactionHashETHModal) => {
    set({
      showTransactionHashETHModal: showTransactionHashETHModal,
    });
  },
  setSeasonZerolVisible: (visible: boolean) => {
    set({
      seasonZeroVisible: visible,
    });
  },
  setHasAccessForClaim: (hasAccessForClaim: boolean) => {
    set({
      hasAccessForClaim: hasAccessForClaim,
    });
  },
  isShowGalaxyModal: true,
  setIsShowGalaxyModal: (isShowGalaxyModal: boolean) => {
    set({
      isShowGalaxyModal: isShowGalaxyModal,
    });
  },
  setIsSupportedWebgl: (isSupportedWebgl: { webgl1: boolean; webgl2: boolean }) => {
    set({
      isSupportedWebgl: isSupportedWebgl,
    });
  },
});
