import React, { useState } from 'react';
import classNames from 'classnames';
import Image from 'next/image';
import { useTranslate } from '@tolgee/react';
import { Button, Typography } from '@/components/Atoms';
import { IdeaSvg } from '@/components/Svg';
import { EAuthVarient } from '@/types/Authentication';
import { useStore } from '@/zustand/store/store';
import styles from './header.module.scss';
import SubmitIdeaModal from '../RoadMapItems/components/SubmitIdeaModal';
import useAuthStatuses from '@/hooks/authentication/useAuthStatuses';
import { HomeSectionProps } from '@/components/NewHome/HeroSection';

const Header = ({ title, subtitle, description, description_array, nameButtonOne, nameButtonTwo }: HomeSectionProps) => {
  const { t } = useTranslate();
  const { setAuthModalVarient } = useStore();
  const [modalVisible, setModalVisible] = useState(false);
  const { isLogin } = useAuthStatuses();
  const handleShowSubmitIdeaModal = () => {
    if (isLogin) {
      setModalVisible(true);
    } else {
      setAuthModalVarient(EAuthVarient.SIGN_IN);
    }
  };

  const gtmClassSubmitStart = isLogin ? 'gtm-roadmap-submit-start-login' : 'gtm-roadmap-submit-start-logout';

  return (
    <div className={styles.container}>
      <div className='flex gap-2'>
        <Typography className={styles.title}>LandRocker {!title ? t('roadmap.title') : t(title)}</Typography>
        <div className='w-[60px] bg-red-500 rounded-lg text-white font-bold flex justify-center items-center gap-2 text-[12px] h-fit p-1'>
          <div className='w-[6px] h-[6px] rounded-full bg-white'></div>
          <Typography className='uppercase'>{nameButtonTwo === undefined ? t('roadmap.live') : t(nameButtonTwo)}</Typography>
        </div>
      </div>

      <div className='flex items-center gap-2'>
        <Typography className={styles.desc}>{!subtitle ? t('roadmap.sub_title') : t(subtitle)}</Typography>
        <div className='w-[100px] h-[25px] md:w-[116px] md:h-[32px]'>
          <Image src='/images/247.png' width={'116px'} height={'32px'} alt='24/7 image' />
        </div>
      </div>
      <Typography className='mt-4 text-[#AAAAAA] text-[13px] md:text-[15px]'>
        {description === undefined ? t('roadmap.description') : t(description)}{' '}
      </Typography>
      <div
        className='w-full max-w-[900px] bg-[#1D2027] mt-[18px] rounded-lg h-auto py-4 md:py-4 px-3 md:px-5 flex 
        flex-col md:flex-row justify-center items-center gap-4 '
      >
        <div className='w-[31px] h-[50px] hidden md:flex'>
          <IdeaSvg />
        </div>
        <Typography className={`text-[13px] md:text-[15px] text-[${!description_array?.[0]?.color ? '#AAAAAA' : description_array?.[0]?.color}]`}>
          {!description_array?.[0]?.text ? t('roadmap.info_first') : t(description_array?.[0]?.text)}
          <a
            onClick={handleShowSubmitIdeaModal}
            className={classNames('underline cursor-pointer hover:text-[#fd804e]', `text-[${description_array?.[1]?.color}]`, gtmClassSubmitStart)}
          >
            {!description_array?.[1]?.text ? t('roadmap.submit_here') : t(description_array?.[1]?.text)}!
          </a>
          {!description_array?.[2]?.text ? t('roadmap.info_second') : t(description_array?.[2]?.text)}!
        </Typography>
        <Button
          onClick={handleShowSubmitIdeaModal}
          className={classNames(
            'bg-white text-[#1D2027] button-hover-effect rounded-[4px] md:rounded-lg text-[14px] w-[102px] h-[32px] font-bold',
            gtmClassSubmitStart,
          )}
        >
          {!nameButtonOne ? t('roadmap.submit') : t(nameButtonOne)}
        </Button>
      </div>
      <SubmitIdeaModal
        opened={modalVisible}
        onClose={() => {
          setModalVisible(false);
        }}
      />

      {/* <Typography className={classNames(styles.desc, 'mt-4')}>
          Have an idea? <a className='text-white underline cursor-pointer'>Submit it here!</a>
        </Typography>
        <Typography className={styles.desc}>
          We’ll review your ideas and, if they align with our schedule and project goals, add them to the roadmap. Thank you for contributing to the
          future of LandRocker!
        </Typography> */}
    </div>
  );
};

export default Header;
