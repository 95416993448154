import { IData } from '@/types/TokenSale/res';
import React from 'react';
import LoadingBalls from '../Common/LoadingBalls';
import CompanyPartnersLayout from '../Home/CompanyPartnersLayout';
import HomeDailyNews from '../Home/DailyNews';
import JoinUsLayout from '../Home/JoinUsLayout';
import RoadmapLayout from '../Home/RoadmapLayout';
import TweetsWrapper from '../Twitter/TweetsWrapper/TweetsWrapper';
import VideosOfInfluencerLayout from '../Home/VideosOfInfluencerLayout';
import CustomDivider from '../Molecules/CustomDivider';
import {
  CommandCenterSection,
  EtheriansSection,
  GameModeSection,
  GameModeSection2,
  GifCards,
  HistorySection,
  HomeLastSection,
  JoinTheTroops,
  NetherexSection,
  PlatformSection,
  TelegramSection,
  DynamicSection,
  MarketplaceSection,
  HomeSwiperSection,
} from '../NewHome';
import AuditedBySection from '../NewHome/AuditedBySection';
import CryptoHunt from '../NewHome/CryptoHunt';

const Components = {
  TelegramSection,
  CommandCenterSection,
  CryptoHunt,
  JoinTheTroops,
  GifCards,
  AuditedBySection,
  GameModeSection,
  MarketplaceSection,
  GameModeSection2,
  HistorySection,
  TweetsWrapper,
  EtheriansSection,
  NetherexSection,
  VideosOfInfluencerLayout,
  // WeaponSection,
  PlatformSection,
  HomeDailyNews,
  HomeLastSection,
  RoadmapLayout,
  CompanyPartnersLayout,
  JoinUsLayout,
  DynamicSection,
  HomeSwiperSection,
};

const DynamicComponent = (props: { data: any[] }) => {
  const { data } = props;
  return (
    <div className='flex flex-col overflow-x-hidden'>
      {!data && (
        <div className='mt-[30px]'>
          <LoadingBalls />
        </div>
      )}
      {data &&
        data
          .sort((a, b) => a.order - b.order)
          .map((item: IData, index: number) => {
            let props = item;
            props.tag = item.tag || null;
            const Component = Components[item.type];
            const showComponent = typeof Components[item.type] === 'function';
            return (
              <div key={item.id}>
                {showComponent ? (
                  <>
                    <Component {...props} />
                    {index === data.length - 1 ? null : <CustomDivider />}
                  </>
                ) : null}
                {index === data.length - 1 ? null : <CustomDivider />}
              </div>
            );
          })}
    </div>
  );
};

export default React.memo(DynamicComponent);
