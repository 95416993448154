import { useCallback, useEffect, useRef, useState } from 'react';
import classnames from 'classnames'; // First, install classnames if not already installed
import Link from 'next/link';
import { useTranslate } from '@tolgee/react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import api from '@/api/services';
import { ArrowLeftSvg, ArrowRightSvg } from '@/components/Svg';
import Wrapper from '@/components/TokenSale/Wrapper';
import { VideosOfInfluencerItem } from './components';
import styles from './VideosOfInfluencerLayout.module.scss';
import useData from '@/hooks/VideosOfInfluencer/useData';
import useTranslationStatus from '@/hooks/useTranslationStatus';
import { getInfluencerVideos } from '@/lib/apis';
import { HomeSectionProps } from '@/components/NewHome/HeroSection';

const VideosOfInfluencerLayout = ({ title, nameButtonOne }: HomeSectionProps) => {
  const { t } = useTranslate();
  const { isReady } = useTranslationStatus();
  const { breakpoints } = useData();
  const [data, setData] = useState<any[]>();
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  useEffect(() => {
    getInfluencerVideos().then((res) => {
      setData(res);
    });
  }, []);
  const swiperRef = useRef(null);

  const renderVideos = useCallback(() => {
    const sortedTemp = data?.slice().sort((a, b) => {
      const orderA = a.order !== null ? a.order : Infinity; // Assign a default value if order is null
      const orderB = b.order !== null ? b.order : Infinity; // Assign a default value if order is null
      // Compare the orders
      return orderA - orderB;
    });

    // Render the sorted videos
    return sortedTemp?.map((item) => (
      <SwiperSlide key={item.id} style={{ order: Number(!item.order) }}>
        <VideosOfInfluencerItem item={item} />
      </SwiperSlide>
    ));
  }, [data]);
  const handlePrevClick = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNextClick = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  return (
    <>
      {isReady ? (
        <div className={styles.container}>
          <Wrapper>
            <h2 className={styles.title}>{isReady && t(title)}</h2>
            <div className='flex justify-end items-end w-full'>
              <Link href='/influencers' passHref>
                <a>
                  <button className='font-bold text-[#00D27E] pb-3'>{t(nameButtonOne)}</button>
                </a>
              </Link>
            </div>
            <Swiper
              ref={swiperRef}
              className='mySwiper'
              modules={[Autoplay, Pagination, Navigation]}
              loop={true}
              speed={6000}
              autoplay={{ delay: 5000, disableOnInteraction: false }}
              onInit={(swiper) => {
                setIsBeginning(swiper.isBeginning);
                setIsEnd(swiper.isEnd);
              }}
              onReachBeginning={() => setIsBeginning(true)}
              onReachEnd={() => setIsEnd(true)}
              onFromEdge={() => {
                setIsBeginning(false);
                setIsEnd(false);
              }}
              pagination={{
                dynamicBullets: true,
                clickable: true,
              }}
              navigation={{
                nextEl: `.${styles['swiper-button-next']}`,
                prevEl: `.${styles['swiper-button-prev']}`,
              }}
              breakpoints={breakpoints}
            >
              {renderVideos()}
            </Swiper>

            {!isBeginning && <ArrowLeftSvg color='black' className={classnames(styles['swiper-button-prev'])} onClick={handlePrevClick} />}
            <ArrowRightSvg color='black' className={classnames(styles['swiper-button-next'])} onClick={handleNextClick} />
          </Wrapper>
        </div>
      ) : (
        false
      )}
    </>
  );
};

export default VideosOfInfluencerLayout;
