import cn from 'classnames';

const HomeIcon = (props) => {
  const { className } = props;
  const rootCn = cn(className, { 'fill-[white]': !className });

  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='15.841'
      height='21.953'
      viewBox='0 0 19.841 21.953'
      className={rootCn}
      {...props}
    >
      <path
        id='Path_11921'
        data-name='Path 11921'
        d='M9.226,11.528a1,1,0,1,1,1-1,1,1,0,0,1-1,1m0-2.948a1,1,0,1,1,1-1,1,1,0,0,1-1,1m2.948,2.948a1,1,0,1,1,1-1,1,1,0,0,1-1,1m0-2.948a1,1,0,1,1,1-1,1,1,0,0,1-1,1M19.8,6.916,11.186.166a.8.8,0,0,0-.973,0L1.6,6.916A2.117,2.117,0,0,0,.783,8.588v8.881a4.485,4.485,0,0,0,4.485,4.485H7.636a.6.6,0,0,0,.6-.6v-4.9a.6.6,0,0,1,.6-.6h3.754a.6.6,0,0,1,.6.6v4.9a.6.6,0,0,0,.6.6H16.14a4.485,4.485,0,0,0,4.485-4.485V8.588A2.1,2.1,0,0,0,19.8,6.916'
        transform='translate(-0.783 0)'
      />
    </svg>
  );
};

export default HomeIcon;
