import { useEffect, useRef, useState } from 'react';
import { isMobile } from 'react-device-detect';
import { Loader, SelectItem, SelectProps } from '@mantine/core';
import { Tooltip } from '@material-tailwind/react';
import classNames from 'classnames';
import { useTranslate } from '@tolgee/react';
import { Button, Typography } from '@/components/Atoms';
import YesSirSvg from '@/components/Svg/YesSirSvg';
import { useStore } from '@/zustand/store/store';
import { STATE_CONDITIONS } from '../../StateCinditions';
import styles from './ConfirmSection.module.scss';
import SelectBox from './SelectBox';

export type ConfirmSectionIProps = {
  timeLeft?: string;
  onClick?: () => void;
  title?: string;
  disabled?: boolean;
  done?: boolean;
  state: 'pending' | 'start' | 'completed' | 'pending_verify' | 'not_verified';
  isMissed: boolean;
  isLocked: boolean;
  role: boolean;
  isGame?: boolean;
};

export type ConfirmSectionInputIProps = {
  select?: Pick<SelectProps, 'onChange' | 'value'> & { data: SelectItem[] };
  disabled?: boolean;
  done?: boolean;
};

// eslint-disable-next-line sonarjs/cognitive-complexity

const gtmClassPrefix = 'gtm-yes_sir';

// eslint-disable-next-line sonarjs/cognitive-complexity
const ConfirmSection = ({ state, isGame, ...props }: ConfirmSectionIProps) => {
  const { t } = useTranslate('commandCenter');
  const ref = useRef<Element | null>(null);
  const { onBoardingData } = useStore();
  const [stateButton, setState] = useState(state);

  useEffect(() => {
    setState(state);
  }, [state]);

  useEffect(() => {
    if (isMobile) {
      ref.current = document.getElementById('fixed-tasks-confirm-section');
    }
  }, []);

  const handleClickButton = () => {
    if (state === 'pending' || state === 'not_verified') {
      setState('pending_verify');
      props.onClick();
    } else {
      props.onClick();
    }
  };

  const stateIsValid = ['pending', 'completed', 'start', 'pending_verify', 'not_verified'].includes(stateButton);
  const normalizeTooltipName = {
    start: 'start',
    completed: 'detail_completed',
    claimable: 'detail_claim',
    pending: 'detial_verify',
    pending_verify: 'detail_pending',
    not_verified: 'not_verified',
  };
  const tooltipContent =
    stateButton == 'start' && props.role
      ? `${t('tooltips.publish')}`
      : props.isMissed
      ? `${t('tooltips.detail_missed')}`
      : props.isLocked
      ? `${t('tooltips.detail_lock')}`
      : stateIsValid
      ? // @ts-ignore
        `${t('tooltips.' + normalizeTooltipName[stateButton])}`
      : t('CENTER_SECTION.achievementSection.button.confirm');

  return (
    <div className={classNames('flex p-0 h-6 mt-auto bg-transparent z-0')}>
      <div className='flex items-center w-full'>
        <div className='flex items-center w-full gap-1 relative'>
          <Tooltip
            content={tooltipContent}
            animate={{
              mount: { scale: 1, y: 0 },
              unmount: { scale: 0, y: 25 },
            }}
            placeholder=''
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          >
            {isGame ? (
              <Button
                onClick={handleClickButton}
                className={classNames(
                  `${gtmClassPrefix}-${stateButton} flex flex-shrink-0 justify-center items-center gap-1 border rounded-md px-2 py-1  min-w-[60px] border-[#00F0FF] text-[#00C9E4] bg-rgba(10, 80, 84, 0.48) transition-all`,
                  onBoardingData.isShowHint && styles.pulse,
                )}
              >
                <YesSirSvg />
                <Typography size='s12ToS12' isBold>
                  {t('CENTER_SECTION.achievementSection.button.confirm')}
                </Typography>
              </Button>
            ) : (
              <Button
                onClick={props.isMissed || props.isLocked ? () => {} : handleClickButton}
                className={classNames(
                  `${gtmClassPrefix}-${stateButton} flex flex-shrink-0 justify-center items-center gap-1 border rounded-md px-1 py-1  min-w-[60px] ${
                    props.isMissed && 'cursor-not-allowed'
                  } ${props.isLocked && 'cursor-not-allowed'} ${
                    props.isMissed
                      ? 'bg-[#D0D0D0] bg-opacity-10'
                      : props.isLocked
                      ? STATE_CONDITIONS?.lock?.bg
                      : stateIsValid
                      ? STATE_CONDITIONS?.[stateButton]?.bg
                      : 'bg-rgba(10, 80, 84, 0.48)'
                  } ${
                    props.isMissed
                      ? 'border-[#D0D0D0]'
                      : props.isLocked
                      ? STATE_CONDITIONS?.lock?.border
                      : stateIsValid
                      ? STATE_CONDITIONS?.[stateButton]?.border
                      : 'border-[#00F0FF]'
                  } ${
                    props.isMissed
                      ? 'text-[#D0D0D0]'
                      : props.isLocked
                      ? STATE_CONDITIONS?.lock.textColor
                      : stateIsValid
                      ? STATE_CONDITIONS?.[stateButton]?.textColor
                      : 'text-[#00C9E4]'
                  } ${(stateButton == 'pending' || stateButton == 'not_verified') && 'hover:bg-[#815b22]'} ${
                    stateButton == 'start' && 'hover:bg-[#1d697b]'
                  } transition-all
            `,
                  onBoardingData.isShowHint && styles.pulse,
                )}
              >
                {stateButton == 'pending_verify' && <Loader size={20} color='cyan' />}
                {/* <div className='me-1'>{props.isMissed ? <MissedSvg /> : <StateIcon />}</div> */}
                <Typography size='s12ToS12' isBold>
                  {stateButton == 'start' && props.role
                    ? 'Publish'
                    : props.isMissed
                    ? `${t('CENTER_SECTION.achievementSection.button.missed')}`
                    : props.isLocked
                    ? t(`${STATE_CONDITIONS?.lock?.text}` as any)
                    : stateIsValid
                    ? t(`${STATE_CONDITIONS?.[stateButton]?.text}` as any)
                    : t('CENTER_SECTION.achievementSection.button.confirm')}
                </Typography>
              </Button>
            )}
          </Tooltip>
          {/* {props.select && <SelectBox items={props.select.data} value={props.select.value} onChange={props.select.onChange} />} */}
        </div>
        {props.timeLeft && (
          <Typography className='text-[#656565] flex-1 text-center' size='s14ToS12'>
            {props.timeLeft}
          </Typography>
        )}
      </div>
    </div>
  );
};

export const ConfirmSectionInput = ({ ...props }: ConfirmSectionInputIProps) =>
  props.select && !props.done ? (
    <div className={classNames('flex p-0 h-6 mt-auto bg-transparent z-0')}>
      <div className='flex items-center w-full gap-1'>
        {props.select && !props.done && <SelectBox items={props.select.data} value={props.select.value} onChange={props.select.onChange} />}
      </div>
    </div>
  ) : null;

export default ConfirmSection;
