/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable import/no-anonymous-default-export */
import { ITaskDetailResponse } from '@/types/Achievements';
import { IAgreementItems, IFAQItems } from '@/types/Agreements';
import { IBlueprintDetailProps } from '@/types/Blueprint';
import { IGetRandomAvatar } from '@/types/ChooseAvatar/res';
import { ICategoryList, IExplore, IFeaturedItemDetail, INewsDetail, IProfileUser, ISliderList } from '@/types/DTO/marketplace';
import { IDropdown } from '@/types/Forms';
import { IFilterByMarketplace } from '@/types/Marketplace';
import { IReferralInputProps } from '@/types/Referral/req';
import { IReferralResponseType } from '@/types/Referral/res';
import { IInTheNews, IPartnerSlider } from '@/types/Sliders/partners';
import { ITopBannerResponseType } from '@/types/StrapiMedia/IStrapiMedia';
import { IAllDailyNeewsVideos, IVideoInfluencerIRes } from '@/types/TokenSale/res';
import { ISignMessage, IWalletDetail } from '@/types/Wallet';
import { axiosInstance } from '..';
import { axiosInstanceStrapi } from '../serviceStrapi';
import { axiosInstanceTwo } from '../serviceTest';
import ReturnRandomChar from '@/utils/ReturnRandomChar';

const currentTag = ReturnRandomChar('a', 'b');
const landingUrl = `/data?params=landings?tag=${currentTag}`;

export default {
  categoryList: () => axiosInstanceTwo.get<ICategoryList>('/api/get/asset/type'),
  signMessage: (params: { owner: string; finger_print: string }) =>
    axiosInstanceTwo.get<ISignMessage>('/api/signe/message', {
      params,
    }),
  walletData: (params: { owner: string; hash_signe: string; message: string; code?: string; finger_print?: string }) =>
    axiosInstanceTwo.get<IWalletDetail>('/api/set/new/user/wallet', { params }),
  explor: (params: { category?: string; offset: number; limit: number; district?: string | string[]; parcel?: string | string[] }) =>
    axiosInstanceTwo.get<IExplore>('/api/get/assets', { params }),
  assetDetail: (params: { category?: string; offset: number; limit: number }) =>
    axiosInstanceTwo.get<IFeaturedItemDetail>('api/get/assets/with/details', { params }),
  latest: () => axiosInstanceTwo.get<IExplore>('/api/get/last/assets?limit=4'),
  detailItem: (params: { id: string }) =>
    axiosInstanceTwo.get<IFeaturedItemDetail>('/api/get/assets/details', {
      params,
    }),

  blueprintDetail: (params: { id: string }) => axiosInstanceTwo.get<IBlueprintDetailProps>(`/api/recipe/get/by/id?id=${params.id}`),

  planetDetails: (params: { id: string }) =>
    axiosInstanceTwo.get<IFeaturedItemDetail>('/api/get/market/planets/detail', {
      params,
    }),

  getMarketplaceDemoList: (params: IFilterByMarketplace) =>
    axiosInstanceTwo.get<IExplore>('/api/get/assets/has/demo/by/filter', {
      params,
    }),
  // getTokenSale: (lang: string) => axiosInstanceStrapi.get<any[]>(`/data?params=landings?_locale=${lang}%26tag=${currentTag}`),
  getTokenSale: (temp?: any) => axiosInstanceStrapi.get<any[]>(`/data?params=landings${temp}`),
  // getTokenSale: (lang: string) => axiosInstanceStrapi.get<any[]>(landingUrl),
  featureProfile: (params: { asset_type: string }) => axiosInstanceTwo.get<IFeaturedItemDetail>('/api/get/assets/details/futures', { params }),
  news: (params: { id: string }) => axiosInstance.get<INewsDetail>('/api/news', { params }),
  images: (params: { images: string }) => axiosInstance.get('/api/images', { params }),
  creatorProfile: (params: { owner: string }) =>
    axiosInstanceTwo.get<IProfileUser>('/api/get/user/profile', {
      params,
    }),
  inventoryBio: (params: { owner: string }) =>
    axiosInstanceTwo.get<IProfileUser>('/api/get/user/profile', {
      params,
    }),
  getDropdown: () => axiosInstanceTwo.get<IDropdown>('/api/web/form/data'),
  getDistrictsList: () => axiosInstanceStrapi.get('/data?params=districts?_sort=id:ASC'),
  getDistrictsDetail: () => axiosInstanceStrapi.get('/data?params=districts-layer-ones?_sort=id:ASC'),
  getDistrictsPageDetail: () => axiosInstanceStrapi.get('/data?params=districts-layer-twos?_sort=id:ASC'),
  getPromotionData: () => axiosInstanceStrapi.get('/data?params=promotion-banners'),
  getContact: (data: { name: string; email: string; message: string }) => {
    const { email, message, name } = data;
    const userData = JSON.stringify({ name: name, email: email, message: message });
    const url = `api/portal/create/data?params=contact-uses&data=${userData}`;
    return axiosInstanceTwo.get<any>(url);
  },
  getSliderData: () => axiosInstanceStrapi.get<ISliderList>('/data?params=sliders?_sort=order:ASC'),
  getPartners: () => axiosInstanceStrapi.get<IPartnerSlider>('/data?params=partners?_sort=order:ASC'),
  getInTheNews: () => axiosInstanceStrapi.get<IInTheNews[]>('/data?params=in-the-news?_sort=order:ASC'),
  getBuilderSlider: () => axiosInstanceStrapi.get('/data?params=builder-sliders?_sort=order:ASC'),
  getPoweredBy: () => axiosInstanceStrapi.get('/data?params=powered-bies?_sort=order:ASC'),
  sendQuote: (data: { business_name: string; email: string; organization_size: any; service: any; message: string }) =>
    axiosInstanceStrapi.post('/data?params=quotes', data),
  whitelist: (data: { asset_id: string; email: string; asset_type_id: string }) => axiosInstanceStrapi.post('/data?params=whitelists', data),
  filterByMarketplace: (params: IFilterByMarketplace) => axiosInstanceTwo.get('/api/get/assets/by/filter', { params }),
  getGender: (params: { gender: number }) =>
    axiosInstanceTwo.get<IGetRandomAvatar>('/api/user/get/rpm/random/avatar', {
      params,
    }),
  whitelistEmail: (params: { email: string }) => axiosInstanceTwo.get('/api/web/white/list', { params }),
  whitelistSeasonZero: () => axiosInstanceTwo.get('/api/pro/game/register/whitelist'),
  whitelistGame: () => axiosInstanceTwo.get('/api/user/set/validate'),
  getAvatarJson: (params: { id: number }) => axiosInstanceTwo.get('/api/user/get/rpm/random/avatar/data', { params }),
  sendGameVerify: (params: { token: any; wallet: any }) => axiosInstanceTwo.get('/auth/confirm/code/action', { params }),
  sendUserIp: (params: { email: string; name: string }) =>
    axiosInstanceTwo.get(`/api/form/actions?form_name=register_main_page&form_data={'name': ${params.name}, 'email': ${params.email}}`),
  getTodays: () => axiosInstanceStrapi.get('/data?params=dayli-updates?_sort=Date:DESC'),
  getPrice: () => axiosInstanceStrapi.get('/data?params=private-sale-confs'),
  getAboutUs: () => axiosInstanceStrapi.get('/data?params=about-uses?_sort=order'),
  getPrivacyPolicy: () => axiosInstanceStrapi.get<IAgreementItems>('/data?params=privacy-policies'),
  getEnduser: () => axiosInstanceStrapi.get<IAgreementItems>('/data?params=end-user-agreements'),
  getTokenPrivateSaleAgreement: () => axiosInstanceStrapi.get<IAgreementItems>('/data?params=private-sale-agreements'),
  getFaq: (lang: string) => axiosInstanceStrapi.get<IFAQItems[]>(`/data?params=faqs?_locale=${lang}%26_sort=order`),
  getRoadMap: () => axiosInstanceStrapi.get('/data?params=roadmaps'),
  checkAgreement: () => axiosInstanceTwo.get('api/user/check/accept/agreement'),
  sendPrivateSell: (data: { name: string; family: string; email: string; wallet: string; lmnt: any; terms: boolean; payment: string }) =>
    axiosInstanceStrapi.post('/data?params=private-sales-data', data),
  signUp: (data: { Email: any }) => axiosInstanceStrapi.post('/data?params=signups', data),

  getEarnMoreRovers: () => axiosInstanceTwo.get('/api/minigame/how/to/earn/more'),
  getBuilderData: () => axiosInstanceStrapi.get('/data?params=builders'),
  getMetaTags: async (route: string) => axiosInstanceStrapi.get(`/data?params=metatags?route=${route}`),
  getChristmasTicket: () => axiosInstanceTwo.get('/api/ticket/get/christmas/ticket/bought'),
  getSoldBuy: (round: number) => axiosInstanceTwo.get(`/pre/sale/buy/round?round=${round}`),

  getMessageforSign: (params: { wallet: string }) => axiosInstanceTwo.get('api/user/add/wallet/sign/message', { params }),
  unSign: () => axiosInstanceTwo.get('api/user/unsigned/wallet'),
  setAcceptAgreement: () => axiosInstanceTwo.get('api/user/Set/accept/agreement'),

  verifyWallet: (params: { wallet: string; message: string; sign: string }) =>
    axiosInstanceTwo.get('api/user/add/wallet', {
      params,
    }),

  setTwitterData: (params: {
    token: string;
    account_type: string;
    account_data: {
      oauth_token: string;
      oauth_token_secret: string;
      user_id: string;
      screen_name: string;
    };
  }) =>
    axiosInstanceTwo.get('api/user/verify/other/acoount', {
      headers: {
        token: params.token,
      },
      params,
    }),
  sendReferralCode: ({ promo_code, locale }: IReferralInputProps) =>
    axiosInstanceTwo.get<IReferralResponseType>(`api/affiliate/get/promo/reward/data?promo_code=${promo_code}&_locale=${locale}`),
  getTopBannerData: () => axiosInstanceStrapi.get<ITopBannerResponseType>('/data?params=dayli-updates?_banner=true'),
  getVideoInfluencer: () => axiosInstanceStrapi.get<IVideoInfluencerIRes>('/data?params=influencer-videos?_sort=created_at:desc'),
  getAllDailyNews: () =>
    axiosInstanceStrapi.post<IAllDailyNeewsVideos>(
      '/breaking-news/',
      { pageNumber: 1, pageSize: 200 },
      {
        baseURL: 'https://admin-hub.landrocker.io',
      },
    ),

  getCommandCenterHomePage: () =>
    axiosInstanceTwo.get('user-achievements/achievement/?home_page=true', {
      baseURL: process.env.NEXT_PUBLIC_TASKS_API,
    }),
  getTaskDetails: (id: string) => axiosInstanceTwo.get<ITaskDetailResponse>(`/actions/get/${id}`, { baseURL: process.env.NEXT_PUBLIC_TASKS_API }),

  getCountDownBanner: () => axiosInstanceTwo.get<ITopBannerResponseType>('/panel/banner/countdown'),
  getXpByWallet: (params: { wallet: string }) => axiosInstanceTwo.get('/api/user/get/user/xp/by/wallet', { params }),
  syncTelegramAccount: (params: { app_token: string; app_name: string }) => axiosInstanceTwo.post('api/sync/other/app', { params }),
};
