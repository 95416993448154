import { Typography } from '@/components/Atoms';
import React, { useEffect, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { HomeDetailDataType } from '../../HeroSection';
import { getDirectusImageUrl } from '@/utils/getUrl';
import { useTranslate } from '@tolgee/react';
interface IBitcoinSection {
  data: HomeDetailDataType;
  imageId?: string;
  mobileImageId?: string;
}
const BitcoinSection = ({ data, imageId, mobileImageId }: IBitcoinSection) => {
  const [isDesktopView, setIsDesktopView] = useState(false);
  const isDesktop = useMediaQuery({
    query: '(min-width: 1024px)',
  });
  const { t } = useTranslate();

  useEffect(() => {
    setIsDesktopView(isDesktop);
  }, [isDesktop]);

  if (!data) return null;

  const { title, subtitle, button } = data;

  const bgMobImage = mobileImageId || imageId;

  return (
    <div
      className='px-4 sm:px-7 md:px-10 lg:px-16 xl:px-20 relative w-full h-[85vh] overflow-hidden  
    bg-bottom lg:bg-center flex items-center justify-center lg:justify-start bg-no-repeat bg-cover'
      style={{
        backgroundImage: !isDesktop ? `url("${getDirectusImageUrl(bgMobImage)}")` : `url("${getDirectusImageUrl(imageId)}")`,
      }}
    >
      <div className='flex flex-col items-center lg:items-start'>
        {/* Title */}
        <div className='flex flex-col text-center lg:block'>
          {title?.map((item, index) => (
            <Typography
              key={index}
              className={`${index === 0 ? 'text-[34px] sm:text-[40px] md:text-[44px] lg:text-[54px] xl:text-[64px]' : 'text-[20px] lg:text-[24px]'}`}
              style={{ color: item.color || 'white' }}
              isBold={index === 0}
            >
              {t(item.text)}
            </Typography>
          ))}
        </div>

        {/* Subtitle */}
        <div className='flex h-fit text-center lg:text-start'>
          <div className='h-auto bg-[#30f7bb7d] w-[2.8px] mr-2 hidden lg:flex'></div>
          <div
            className='lg:p-[21px] xl:min-w-[800px] rounded-md flex flex-col mt-5 lg:mt-0'
            style={{
              background: !isDesktopView ? 'transparent' : 'linear-gradient(90deg, rgba(48,247,186,0.2) 0%, rgba(48,247,186,0) 49%)',
            }}
          >
            {subtitle.slice(0, -2)?.map((item, index) => (
              <Typography
                key={index}
                size={
                  item.size?.includes('large')
                    ? 's40ToS24'
                    : item.size?.includes('medium')
                    ? 's36ToS22'
                    : item.size?.includes('small')
                    ? 's26ToS16'
                    : 's20ToS14' // Default size if none specified
                }
                style={{
                  color: item.color || 'white',
                }}
                className={`xl:leading-[48px] ${index === 1 ? 'font-bold' : ''} ${
                  index === 1 ? 'text-[26px] sm:text-[26px] md:text-[30px] lg:text-[36px] xl:text-[46px]' : ''
                }`}
              >
                {t(item.text)}
              </Typography>
            ))}
            <div className='flex items-center gap-1'>
              {subtitle?.slice(-2).map((item, index) => (
                <Typography
                  key={index}
                  style={{
                    color: item.color || 'white',
                  }}
                  className={`xl:leading-[48px] font-bold text-[26px] sm:text-[26px] md:text-[30px] lg:text-[36px] xl:text-[46px]`}
                >
                  {t(item.text)}
                </Typography>
              ))}
            </div>
          </div>
        </div>

        {/* Description */}
        <div className='flex flex-col mt-4 lg:mt-2 text-center lg:text-start'>
          <Typography
            size='s26ToS16'
            style={{
              color: data.description[0].color || 'white',
            }}
          >
            {t(data.description[0].text)}
            <Typography
              style={{
                color: data.description[1].color || 'white',
              }}
              size='s36ToS22'
            >
              {t(data.description[1].text)}
            </Typography>{' '}
            {t(data.description[2].text)}
          </Typography>
          <Typography
            style={{
              color: data.description[3].color || 'white',
            }}
            className='leading-6'
            size='s36ToS22'
            isBold
          >
            {t(data.description[3].text)}
          </Typography>
          <Typography
            style={{
              color: data.description[4].color || 'white',
            }}
            size='s26ToS16'
            className='mt-2'
          >
            {t(data.description[4].text)}
          </Typography>
        </div>

        {/* Button */}
        {button?.[0] && (
          <Typography className='text-white mt-4' size='s20ToS16' style={{ color: button[0].text_color || 'white' }}>
            {t(button[0].text)}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default BitcoinSection;
