import { useResponsive } from '@/hooks';
import { getDirectusImageUrl } from '@/utils/getUrl';
import Image from 'next/image';
import React, { useEffect, useState } from 'react';

const DynamicSection = (props) => {
  const { isMobile } = useResponsive();
  const markup = { __html: props.code };
  return (
    <div
      className='relative w-full h-auto bg-no-repeat bg-cover bg-center'
      style={{
        backgroundImage: `url(${
          isMobile
            ? props.bgMob === undefined
              ? getDirectusImageUrl(props.bgImageMobile)
              : getDirectusImageUrl(props.bgMob)
            : props.backgroundImage === undefined
            ? getDirectusImageUrl(props.bgImageDesktop)
            : getDirectusImageUrl(props.backgroundImage)
        })`,
      }}
    >
      <div dangerouslySetInnerHTML={markup} className='z-10'></div>
    </div>
  );
};

export default DynamicSection;
