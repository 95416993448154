import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import cn from 'classnames';
import Image from 'next/image';
import { useTranslate } from '@tolgee/react';
import { useAccount } from 'wagmi';
import SoldOutBox from '@/components/Inventory/SoldoutBox';
import AvailableCoins from '@/components/marketplace/AvailableCoins';
import { EAuthVarient } from '@/types/Authentication';
import { IFuel } from '@/types/MiningCards/cardInterface';
import { numberSeparator } from '@/utils/numberSeparator';
import { findRarityColorByName } from '@/utils/rarityColorFunc';
import { useStore } from '@/zustand/store/store';
import MarketplaceCardLinkWrapper from '../LinkWrapper';
import { EMiningCardType } from '../MiningCard';
import styles from './GeneralCard.module.scss';
import useAuthStatuses from '@/hooks/authentication/useAuthStatuses';
import useConnectWallet from '@/hooks/common/useConnectWallet';
import dynamic from 'next/dynamic';

const GLBModelWithAnimation = dynamic(() => import('@/components/marketplace/GlbModelWithAnimation'), { ssr: false });

interface IProps {
  cardType: EMiningCardType;
  data: IFuel;
  tokenPrice: string;
}

const urls: Record<'Companion' | 'Weapon' | 'TacticalTools' | 'Suit' | 'Loot Box', string> = {
  Companion: 'companions',
  Weapon: 'weapons',
  TacticalTools: 'tactical-tools',
  Suit: 'suits',
  'Loot Box': 'lootboxes',
};

const GeneralCard = ({ data, tokenPrice, cardType }: IProps) => {
  const { t } = useTranslate('marketPlace');
  const { isLoading, setIsShwoBuyModal, setActiveBuyItem, setAuthModalVarient, isSupportedWebgl } = useStore();
  const { isLogin } = useAuthStatuses();
  const { address } = useAccount();
  const [dollarPrice, setDollarPrice] = useState('0');
  const { handleClickWallet } = useConnectWallet();
  const [isSoldOut, setIsSoldOut] = useState(false);
  useEffect(() => {
    if (data.limitCount == data.sellCount) {
      setIsSoldOut(true);
    } else {
      setIsSoldOut(false);
    }
  }, [data]);
  useEffect(() => {
    if (tokenPrice !== undefined) {
      let dollarPriceLrt = (data?.priceInLRT * Number(tokenPrice)).toFixed(2);
      let numberSeprator = numberSeparator(dollarPriceLrt, ',').toString();
      setDollarPrice(numberSeprator);
    }
  }, [tokenPrice]);

  const handleClickItem = () => {
    if (isLogin && address) {
      setActiveBuyItem({
        image: data.image,
        name: 'Loot Box',
        value: data.amount.toString(),
        price: data.priceInLRT,
        titleModal: 'Buy Loot Box',
        index: data.index,
      });
      setIsShwoBuyModal(true);
    } else {
      if (!isLogin) {
        setAuthModalVarient(EAuthVarient.SIGN_IN);
      } else if (address == undefined) {
        handleClickWallet();
      }
    }
  };

  const content = (
    <div
      className={cn(
        styles.wrapper,
        cardType !== EMiningCardType.Lootbox ? 'cursor-pointer' : 'cursor-default',
        `gtm-marketplace-card-${urls[cardType]}-${data?.id}`,
      )}
      // uncomment this for lootbox function
      // onClick={cardType === 'Lootbox' && handleClickItem}
    >
      {/* {!isSoldOut && isHovered && <HoverBox />} */}
      {isSoldOut && <SoldOutBox />}
      <div className={styles.header}>
        <div className={styles.imageBox}>
          {!isMobile && data.model ? (
            <div className='max-w-[340px] aspect-square relative'>
              {isSupportedWebgl.webgl1 && isSupportedWebgl.webgl2 ? (
                <GLBModelWithAnimation url={data.model} />
              ) : (
                <Image
                  src={isLoading ? '/images/NFT.svg' : data.image}
                  layout='fill'
                  placeholder='blur'
                  blurDataURL='/images/NFT.svg'
                  loading={'lazy'}
                  style={{ transition: 'all 1s' }}
                  alt='NFT card'
                  // unoptimized
                />
              )}
            </div>
          ) : (
            <Image
              src={isLoading ? '/images/NFT.svg' : data.image}
              layout='fill'
              placeholder='blur'
              blurDataURL='/images/NFT.svg'
              loading={'lazy'}
              style={{ transition: 'all 1s' }}
              alt='NFT card'
              // unoptimized
            />
          )}
        </div>
        <div className={styles.info}>
          <span className={styles.title}>{data.name}</span>
          {/* <span className={styles.owner}>
            <span className='text-[#727272]'>{t('general.owner')}: </span>
            LandRocker
          </span> */}
        </div>
        <div className='flex gap-1 text-[11px]'>
          <span
            className='px-2 py-1 rounded-full text-center'
            style={{ background: findRarityColorByName(data.rarityName).bgColor, color: findRarityColorByName(data.rarityName).textColor }}
          >
            {data.rarityName}
          </span>
          <span className='bg-[#72727251] text-[#878BFB] px-2 py-1 rounded-full text-center'>{t('roverCard.premier')}</span>
          <span className='bg-[#29d6df3f] text-[#29D6DF] px-2 py-1 rounded-full text-center'>{t('roverCard.limited')}</span>
        </div>
      </div>
      <div className={cn(styles.footer, styles.planetsFooter)}>
        {cardType !== 'Loot Box' ? (
          <div className={styles.lrt}>
            <div>{numberSeparator(data.priceInLRT, ',')}</div>
            <div className='mx-1'>{data?.currency} </div>
          </div>
        ) : (
          <>{t('generalCard.comingSoon')}</>
        )}
        {/* <div className={styles.tilda}>S</div>
        <div className={styles.usd}>
    <div className={styles.value}>${dollarPrice}</div>
  </div> */}
        <div className={styles.usd}>
          <div className={styles.value}>
            {data.sellCount}/{data.limitCount}
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <MarketplaceCardLinkWrapper isLink={cardType !== EMiningCardType.Lootbox} href={`/marketplace/${urls[cardType]}/${data?.id}`}>
      {content}
    </MarketplaceCardLinkWrapper>
  );
};
export default GeneralCard;
