/* eslint-disable i18next/no-literal-string */
export const ctaGenerator = (tweetId, type: string): string => {
  const baseUrl = 'https://twitter.com/intent';
  switch (type) {
    case 'view':
      return `https://twitter.com/landrocker_io/status/${tweetId}`;
    case 'like':
      return `${baseUrl}/like?tweet_id=${tweetId}`;
    case 'repost':
      return `${baseUrl}/retweet?tweet_id=${tweetId}`;
    case 'reply':
      return `${baseUrl}/tweet?in_reply_to=${tweetId}`;
    case 'quote':
      return `${baseUrl}/tweet?text=https://twitter.com/landrocker_io/status/${tweetId}`;
    default:
      return `https://twitter.com/status/${tweetId}`;
  }
};
