import React from 'react';
import { useEffect, useState } from 'react';
import { useTranslate } from '@tolgee/react';

const useTranslationStatus = () => {
  const { t } = useTranslate();
  const [isReady, setIsReady] = useState();

  useEffect(() => {
    setIsReady(<>{t(t)}</>);
  }, [t]);

  return { isReady };
};

export default useTranslationStatus;
