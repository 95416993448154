import { StateCreator } from 'zustand';
import { IInventorySlice } from './Interface';

export const inventorySlice: StateCreator<IInventorySlice> = (set) => ({
  currentClaimModal: 'close',
  claimableAmount: 0,
  planIDClaim: '0x0',
  refreshVestingData: false,
  callAssetEndpoint: false,
  setCurrentClaimModal: (currentClaimModal) => {
    set({ currentClaimModal });
  },
  setCallAssetEndpoint: (callAssetEndpoint) => {
    set({ callAssetEndpoint });
  },
  setClaimableAmount: (claimableAmount) => {
    set({ claimableAmount });
  },
  setPlanIDClaim: (planIDClaim) => {
    set({ planIDClaim });
  },
  setRefreshVestingData: (refreshVestingData) => {
    set({ refreshVestingData });
  },
});
