import { StateCreator } from 'zustand';
import { IAuthSignup } from '@/types/Authentication';
import { IUserSlice } from './Interface';

export const userSlice: StateCreator<IUserSlice> = (set) => ({
  //state initializing
  walletAddress: undefined,
  userData: {
    Assets: {},
    email: '',
    new_user: false,
    referral: '',
    status: '',
    token: '',
    username: '',
  },
  userLevel: 0,
  phoneNumber: '',
  changeUsernameInput: false,
  twoFAEnabled: false,
  changeTelegramInput: false,
  telegramData: { username: 'mohammadreza' },
  //state handler functions
  setTelegramData: (telegramData: any) => {
    set({
      telegramData: telegramData,
    });
  },
  setWalletAddress: (newWallet: string) => {
    set({
      walletAddress: newWallet,
    });
  },
  setUserData: (userData: IAuthSignup) => {
    set({
      userData: userData,
    });
  },
  setPhoneNumber: (phoneNumber: string) => {
    set({ phoneNumber: phoneNumber });
  },
  setUserLevel: (userLevel: number) => {
    set({
      userLevel: userLevel,
    });
  },
  setChangeUsernameInput: (changeUsernameInput: boolean) => {
    set({ changeUsernameInput });
  },
  setChangeTelegramInput: (changeTelegramInput: boolean) => {
    set({ changeTelegramInput });
  },
  setTwoFAEnabled: (twoFAEnabled: boolean) => {
    set({ twoFAEnabled });
  },
});
