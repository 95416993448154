import React, { useEffect, useState } from 'react';
import Link from 'next/link';
import HomeGifCard from '@/components/Molecules/HomeGifCard';
import { HomeSectionProps } from '../HeroSection';

export interface GifCardsProps {}

const GifCards: React.FunctionComponent<HomeSectionProps> = (props) => {
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  return (
    <>
      {isClient && (
        <div className='min-h-[85vh] pt-20 pb-28 px-4 sm:px-7 md:px-10 lg:px-16 xl:px-20 p-4 bg-[#000000] flex flex-col justify-center'>
          <div className='flex flex-wrap items-start justify-around'>
            {props.details.map((card) => (
              <HomeGifCard key={card.title} {...card} />
            ))}
          </div>
          <Link href={props.linkButtonOne} passHref>
            <a>
              <div className='gtm-learn-gif-section w-[260px] h-[45px] button-hover-effect flex justify-center items-center rounded-lg mx-auto mt-10 lg:mt-20 border cursor-pointer'>
                {props.nameButtonOne}
              </div>
            </a>
          </Link>
        </div>
      )}
    </>
  );
};

export default GifCards;
