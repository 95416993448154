import React from 'react';
import { useTranslate } from '@tolgee/react';
import { Typography } from '@/components/Atoms';

const SoldOutBox = () => {
  const { t } = useTranslate('marketPlace');
  return (
    <div
      className=' absolute top-0 left-0 w-full  bg-[#00000000] z-[3] flex justify-center 
  items-start text-red-500'
    >
      <div className={'w-full p-3 backdrop-brightness-[45%]'} style={{ aspectRatio: '1 / 1' }}>
        <div className='border border-[#EC3E3E] w-full h-[calc(100%-24px)] sm:h-full flex justify-center items-center uppercase rounded-xl text-shadow-lg font-bold'>
          <Typography size='s26ToS16'>{t('general.soldOut')}</Typography>
        </div>
      </div>
    </div>
  );
};

export default SoldOutBox;
