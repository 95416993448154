import { toast } from 'react-toastify';
import { useRouter } from 'next/router';
import api from '@/api/services/achievements';
// import SocialStatus from '@/hooks/inventory/SocialStatus';
import { normalKey } from '@/components/Achievements/CampaignCards/components/_taskCardList2';
import { ITaskActionDataType } from '@/types/Achievements';

const botId = '7331624137';
// const botId = '6675274941';
const baseUrl = 'https://landrocker.io';
const redirectUrl = (taskId: string) => `${baseUrl}command-center`;

const useTelegram = (item: ITaskActionDataType & { referal_user_id?: string; userId?: string; achievementId?: string }, isVerified: boolean) => {
  const router = useRouter();
  // const domain = window?.location.hostname;
  // const url = 'https://portal-stage.landrocker.io';
  const telegramAuthUrl = `https://oauth.telegram.org/auth?bot_id=${botId}&origin=${redirectUrl(
    item.id,
  )}&redirect_uri='${baseUrl}'&data-auth-url='${redirectUrl(item.id)}'&embed=1`;
  // const telegramAuthUrl = `https://oauth.telegram.org/auth?bot_id=6651040289&origin=${url}/command-center&redirect_uri='${url}'&data-auth-url='${url}/command-center'&embed=1`;
  // const telegramAuthUrl = `https://oauth.telegram.org/auth?bot_id=6651040289&origin=${url}/command-center&redirect_uri='${url}'&data-auth-url='${url}/command-center'&embed=1`;
  // const url = 'https://portal-test.landrocker.io';
  // const telegramAuthUrl = `https://oauth.telegram.org/auth?bot_id=6821094257&origin=${url}/command-center&redirect_uri='${url}'&data-auth-url='${url}/command-center'&embed=1`;
  // const { achievementData, setachievementData } = useStore();
  // @ts-ignore
  // const channelName = item?.additional_data && JSON.parse(item.additional_data)?.channel_name;

  // const { userFollowedTelegram, telAuth, callSetUserid } = SocialStatus();

  const startTelegramFlow = async () => {
    // if (!isVerified) {
    //   router.push(telegramAuthUrl);
    //   return;
    // }

    try {
      const type =
        item.action_type.Name === 'referral_telegram'
          ? 'generate_referral_message'
          : item.action_type.Name === 'network_telegram'
          ? 'generate_referral_network'
          : 'landrocker_join';
      await api.doneTelegram({ id: item?.id, achievement_id: item.achievementId, type, referal_user_id: item?.referal_user_id }).then((res) => {
        if (res?.data?.error) {
          toast.error(res.data.error, {
            position: 'top-center',
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            style: {
              background: '#5a0000',
              color: 'white',
            },
          });
          return;
        }
        api
          .doneTask({ id: item?.id, data: item.additional_data, achievement_id: item.achievementId, referal_user_id: item?.referal_user_id })
          .then((response) => {
            window.open(res.data.url, '_blank');
          });
      });
    } catch (error) {
      toast.error('An unexpected issue occurred. Please try again later.', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        style: {
          background: '#5a0000',
          color: 'white',
        },
      });
    }
  };

  return { startTelegramFlow, telegramAuthUrl };
};

export default useTelegram;
