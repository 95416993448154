import React from 'react';
import cn from 'classnames';
import Link from 'next/link';
import { useTranslate } from '@tolgee/react';
import { Typography } from '@/components/Atoms';
import styles from './HelpCenter.module.scss';
import { HELP_LINKS } from './staticData';
import useTranslationStatus from '@/hooks/useTranslationStatus';

const HelpCenter = () => {
  const { t } = useTranslate('newHome');
  const { isReady } = useTranslationStatus();

  return (
    <div className={cn(styles.wrapper)}>
      <ul>
        {HELP_LINKS.map((i) => (
          <li className='flex gap-4 items-center mb-1 pe-5' key={i.title}>
            <Link href={i.href} passHref>
              <a target='_blank' className='w-full'>
                <div className={styles.listItem}>
                  {i.icon && <i.icon />}
                  {/* @ts-ignore */}
                  <Typography size='s14ToS12'>{isReady && i.title && t(`help.${i.title}`)}</Typography>
                </div>
              </a>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default HelpCenter;
