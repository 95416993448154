import { readItems } from '@directus/sdk';
import directus from './directus';

export const getInfluencerVideos = async () => {
  try {
    const results = await directus.request(readItems('Influencer_videos'));
    return results;
  } catch (error) {
    console.error('Error fetching influencer videos:', error);
  }
};

export const getEndUserAgreement = async () => {
  try {
    const results = await directus.request(readItems('Enduseragreement'));
    return results;
  } catch (error) {
    console.error('Error fetching end user agreement:', error);
  }
};

export const getEndUserData = async () => {
  try {
    const results = await directus.request(readItems('EndUserData'));
    return results;
  } catch (error) {
    console.error('Error fetching end user data:', error);
  }
};

export const getPrivacyPolicy = async () => {
  try {
    const results = await directus.request(readItems('Privacypolicy'));
    return results;
  } catch (error) {
    console.error('Error fetching privacy policy:', error);
  }
};

export const getAgreement = async () => {
  try {
    const results = await directus.request(readItems('agreement'));
    return results;
  } catch (error) {
    console.error('Error fetching agreement:', error);
  }
};

export const getAboutUs = async () => {
  try {
    const results = await directus.request(readItems('Aboutus'));
    return results;
  } catch (error) {
    console.error('Error fetching about us:', error);
  }
};

export const getPartners = async () => {
  try {
    const results = await directus.request(readItems('Partners'));
    return results;
  } catch (error) {
    console.error('Error fetching partners:', error);
  }
};

export const getFAQs = async () => {
  try {
    const results = await directus.request(readItems('Faq'));
    return results;
  } catch (error) {
    console.error('Error fetching faqs:', error);
  }
};

export const getFAQData = async () => {
  try {
    const results = await directus.request(readItems('faq_data'));
    return results;
  } catch (error) {
    console.error('Error fetching faq_data:', error);
  }
};

export const getHomeSection = async () => {
  try {
    const results = await directus.request(readItems('home_section'));
    return results;
  } catch (error) {
    console.error('Error fetching products:', error);
  }
};

export const getBattleOfRovers = async () => {
  try {
    const results = await directus.request(readItems('battle_of_mars'));
    return results;
  } catch (error) {
    console.error('Error fetching products:', error);
  }
};

export const getCommandCenterBanner = async () => {
  try {
    const results = await directus.request(readItems('command_center_banner'));
    return results;
  } catch (error) {
    console.error('Error fetching products:', error);
  }
};

export const getGamesList = async () => {
  try {
    const results = await directus.request(readItems('games_list'));
    return results;
  } catch (error) {
    console.error('Error fetching products:', error);
  }
};

export const getGamesSliders = async () => {
  try {
    const results = await directus.request(readItems('games_slider'));
    return results;
  } catch (error) {
    console.error('Error fetching products:', error);
  }
};

export const getTweetList = async () => {
  try {
    const results = await directus.request(readItems('twitter_list'));
    return results;
  } catch (error) {
    console.error('Error fetching products:', error);
  }
};
