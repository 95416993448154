import React, { useRef, useState } from 'react';
import { isIOS } from 'react-device-detect';
import classNames from 'classnames';
import { useTranslate } from '@tolgee/react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useResponsive } from '@/hooks';
import poster0 from '@/public/images/new-home/poster0.jpg';
import poster1 from '@/public/images/new-home/poster1.jpg';
import SectionContainer from '../../Molecules/SectionContainer';
import styles from './index.module.scss';
import { Swiper as SwiperInstance } from 'swiper/types';
import { HomeSectionProps } from '../HeroSection';
import { getDirectusImageUrl } from '@/utils/getUrl';

export interface JoinTheTroopsProps {}

const JoinTheTroops: React.FunctionComponent<HomeSectionProps> = (props) => {
  const { t } = useTranslate();
  const { isDesktop1024 } = useResponsive();
  const { isMobile } = useResponsive();

  const swiperContainer = useRef<SwiperInstance>(null);
  const videoRefs = useRef<Array<HTMLVideoElement | null>>([]); // Store refs for all video elements

  const iosVideoURLs = [
    'https://landrockercdn.s3.eu-central-1.amazonaws.com/website_content/video/mobile_PTE_C.mp4',
    'https://landrockercdn.s3.eu-central-1.amazonaws.com/website_content/video/mobile_PTL_C.mp4',
  ];

  const videoURLs = [
    'https://landrockercdn.s3.eu-central-1.amazonaws.com/website_content/video/mobile_PTE_C.webm',
    'https://landrockercdn.s3.eu-central-1.amazonaws.com/website_content/video/mobile_PTL_C.webm',
  ];

  const videos = isIOS ? iosVideoURLs : videoURLs;

  // Keep track of the current URL and active slide index
  const [activeIndex, setActiveIndex] = useState<number>(0); // Initialize with 0 as the starting index

  const handlePlay = (index: number) => {
    setActiveIndex(index); // Update the active index for the video being played
    videoRefs.current.forEach((video, idx) => {
      if (video) {
        if (idx === index) {
          video.play(); // Play the active video
        } else {
          video.pause(); // Pause the inactive videos
          video.currentTime = 0; // Reset time for inactive videos
        }
      }
    });
  };

  const getPoster = (index: number) => {
    return index === 0 ? poster1.src : poster0.src;
  };

  // Handle pagination click to navigate to specific slide
  const handlePaginationClick = (index: number) => {
    if (swiperContainer.current) {
      swiperContainer.current.slideTo(index); // Programmatically slide to the selected index
      handlePlay(index); // Handle video play logic
    }
  };

  // Handle the video end event to go to the next slide
  const handleVideoEnd = () => {
    const nextIndex = (activeIndex + 1) % videos.length; // Calculate the next index
    swiperContainer.current?.slideTo(nextIndex); // Move to the next slide
    handlePlay(nextIndex); // Play the video in the new slide
  };

  return (
    <SectionContainer
      imageXlMinW='600px'
      mt={343}
      title={t(props.title)}
      description={t(props.description)}
      backgroundImage={getDirectusImageUrl(props.backgroundImage)}
      mobileImage={getDirectusImageUrl(props.bgMob)}
      hasLine
      aboveTitle={t(props.subtitle)}
      contentPosition='right'
      imageUrl={isDesktop1024 ? undefined : getDirectusImageUrl(props.image)}
      buttons={
        <div className='flex flex-col mb-10 lg:mb-20'>
          <div className='w-full max-w-[340px] md:max-w-[510px] 2xl:max-w-[600px] flex justify-start items-start'>
            <Swiper
              onSwiper={(swiper) => (swiperContainer.current = swiper)}
              className={classNames(styles.troopsSwiper, 'pb-4')}
              spaceBetween={20}
              slidesPerView={1}
              loop={true}
              navigation={true}
              onSlideChange={(swiper) => handlePlay(swiper.activeIndex)} // Update active index on slide change
            >
              {videos.map((videoUrl, index) => (
                <SwiperSlide key={index}>
                  <div className='rounded-2xl overflow-hidden'>
                    {/* Video Element with autoPlay and ref */}
                    <video
                      ref={(el) => (videoRefs.current[index] = el)} // Store reference to each video
                      width={'100%'}
                      height={'100%'}
                      src={videoUrl}
                      loop={false} // Disable loop to use the onEnded event
                      muted={true}
                      autoPlay={false} // Don't autoplay, handle manually
                      controls={true} // Enable controls
                      poster={getPoster(index)}
                      playsInline={true}
                      onEnded={handleVideoEnd} // Trigger onEnded when the video finishes
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
          {/* Custom Pagination */}
          <div className='flex gap-2'>
            <span
              className='h-1 w-12 transition-colors rounded-lg cursor-pointer'
              style={{
                backgroundColor: activeIndex === 0 ? 'white' : '#d9d9d933 ', // Active pagination color
              }}
              onClick={() => handlePaginationClick(0)} // Navigate to slide 0 on click
            />
            <span
              className='h-1 w-12 transition-colors rounded-lg cursor-pointer'
              style={{
                backgroundColor: activeIndex === 1 ? 'white' : '#d9d9d933 ', // Active pagination color
              }}
              onClick={() => handlePaginationClick(1)} // Navigate to slide 1 on click
            />
          </div>
        </div>
      }
      imageClasses='mt-[50px]'
    />
  );
};

export default JoinTheTroops;
