import Image from 'next/image';
import { useRouter } from 'next/router';
import { useTranslate } from '@tolgee/react';
import { Typography } from '@/components/Atoms';
import CommandCenterTasksHome from './_tasks';
import styles from './CommandCenterSection.module.scss';
import classNames from 'classnames';
import { HomeSectionProps } from '../HeroSection';
import { getDirectusImageUrl } from '@/utils/getUrl';

const CommandCenterSection = (props: HomeSectionProps) => {
  const { t } = useTranslate();
  const router = useRouter();
  const Button = (
    <button
      onClick={() => router.push(props.linkButtonOne)}
      className={classNames(styles.mainBtn, 'button-hover-effect rounded-[4px] md:rounded-lg')}
    >
      {t(props.nameButtonOne)}
    </button>
  );
  return (
    <div
      className={styles.commandCenterContainer}
      style={{
        background: 'linear-gradient(176deg, rgba(0,0,0,1) 0%, rgba(2,42,52,1) 50%, rgba(0,0,0,1) 100%)',
      }}
    >
      <div className='hidden lg:block lg:col-span-3 -mb-[6px]'>
        <Image src={getDirectusImageUrl(props.image)} layout='responsive' height={'100%'} width={'100%'} alt='commander' />
      </div>
      <div className='col-span-12 lg:col-span-9 xl:col-span-9'>
        <div className='flex flex-col md:justify-start justify-center md:items-start w-full h-full'>
          <div className='flex justify-between items-center w-full'>
            <div className='flex flex-col items-start mb-2'>
              <Typography className='font-bold leading-8' Component={'h2'} size='s40ToS24'>
                {t(props.title)}
              </Typography>
              <Typography Component={'p'} size='s20ToS16' className='text-[#C9C5CA]'>
                {t(props.subtitle)}
              </Typography>
            </div>
            <div className='hidden md:block my-2'>{Button}</div>
          </div>
          <Typography className='text-start text-[#C9C5CA]' Component={'p'} size='s16ToS14'>
            {t(props.description)}
          </Typography>
          <CommandCenterTasksHome />
        </div>
      </div>
      <div className=' block col-span-12 md:hidden xl:col-span-2 min-w-[250px] mt-3 mb-4 rounded-[4px] md:rounded-lg'>{Button}</div>
    </div>
  );
};

export default CommandCenterSection;
