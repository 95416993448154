import * as React from 'react';
import { SVGProps } from 'react';

interface ComponentProps extends SVGProps<SVGSVGElement> {
  classnames?: string;
}

const XSvg = (props: ComponentProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={'100%'}
    height={'100%'}
    viewBox='0 0 21.226 21.733'
    className={props.classnames}
    {...props}
  >
    <path
      fill="#fafafa"
      fillRule="evenodd"
      d="M.129.187c.072.1 1.728 2.513 3.678 5.355s3.757 5.473 4.011 5.841.462.682.462.689a3.3 3.3 0 0 1-.211.261c-.115.134-.443.512-.725.842s-.761.883-1.06 1.232l-1.173 1.364-1.378 1.6c-1.278 1.486-1.465 1.7-2.436 2.836-.519.605-1.019 1.185-1.108 1.287a1.306 1.306 0 0 0-.163.211 7.245 7.245 0 0 0 .919.024h.919l1.012-1.177c.555-.648 1.106-1.287 1.22-1.417.246-.285 2.135-2.479 2.292-2.668.06-.072.148-.175.2-.227s.419-.483.826-.957l.763-.888c.014-.014.23-.266.479-.555s.462-.531.471-.531 1.29 1.855 2.848 4.121 2.857 4.161 2.891 4.212l.062.089h3.156c2.594 0 3.154 0 3.144-.031s-1.522-2.233-5.4-7.878c-2.8-4.068-3.168-4.614-3.156-4.642s.39-.471 2.891-3.381c.428-.5 1.019-1.187 1.314-1.529l.658-.766c.069-.079.431-.5.8-.933s1.007-1.173 1.409-1.639.747-.869.763-.89c.031-.038-.022-.041-.9-.041h-.933l-.416.483a911.285 911.285 0 0 1-2.185 2.537c-.065.072-.2.227-.29.335s-.56.648-1.039 1.208-.878 1.022-.885 1.029-.11.127-.227.266c-.206.244-.414.486-1.374 1.6-.421.488-.443.51-.479.464-.022-.026-1.254-1.821-2.742-3.987l-2.7-3.941H0l.129.187m2.472 1.29c.024.036.615.885 1.314 1.886l9.89 14.155c1.072 1.534 1.96 2.8 1.977 2.819a10.333 10.333 0 0 0 1.457.024l1.429-.007L14.93 15 8.316 5.536 5.44 1.42l-1.441-.007-1.441-.007.043.067"
    />
  </svg>
);
export default XSvg;
