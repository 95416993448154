import React from 'react';
import { useTranslate } from '@tolgee/react';
import SectionHeader from '../../Molecules/SectionHeader';
import 'swiper/css';
import 'swiper/css/pagination';
import HomeGifCard from '@/components/Molecules/HomeGifCard';
import { HomeSectionProps } from '../HeroSection';

const PlatformSection = (props: HomeSectionProps) => {
  const { t } = useTranslate();

  return (
    <div className='pt-20 pb-28 px-4 sm:px-7 md:px-10 lg:px-16 xl:px-20 bg-[#000000]'>
      <SectionHeader title={t(props.title)} subTitle={t(props.subtitle)} />
      <div className='flex flex-wrap justify-around'>
        {props?.details?.map((card, index) => (
          <HomeGifCard key={index} {...card} className='max-w-[400px] !xl:max-w-[500px]' />
        ))}
      </div>
    </div>
  );
};

export default PlatformSection;
