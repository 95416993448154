import React from 'react';
import Link from 'next/link';
import { Typography } from '@/components/Atoms';
import { CustomVideo } from '@/components/Press/Videos/components';
import { IVideoInfluencerItem } from '@/types/TokenSale/res';
import styles from './VideosOfInfluencerItem.module.scss';

const VideosOfInfluencerItem = ({ item }: { item: IVideoInfluencerItem }) => {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { prefix_cover, link, prefix_video, name, title } = item;
  return (
    <>
      <div className={styles.container}>
        <Link href={link}>
          <a target='_blank' rel='noopener noreferrer'>
            <CustomVideo isCustomUrl posterUrl={`${prefix_cover}${name}.jpg`} url={`${prefix_video}${name}.mp4`} youtube={false} />
            <div className={styles.youtubeUrlContainer}>
              <Typography size='s20ToS16' isBold className='underline text-[16px]'>
                {title}
              </Typography>
            </div>
          </a>
        </Link>
      </div>
    </>
  );
};

export default VideosOfInfluencerItem;
