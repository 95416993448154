/* eslint-disable i18next/no-literal-string */
import React from 'react';
import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useTranslate } from '@tolgee/react';
import { DiscordSvg, MediumSvg, TelegramSocialSvg } from '@/components/Svg';
import TwitterSvg from '@/components/Svg/twitter';
import { IGetFollowers } from '@/types/TokenSale/res';
import styles from './SocialMedia.module.scss';
import SocialMediaItem from './SocialMediaItem';
import useTranslationStatus from '@/hooks/useTranslationStatus';

const SocialMediaTokenSale = ({ data }: { data: IGetFollowers }) => {
  const { t } = useTranslate('home');
  const { isReady } = useTranslationStatus();
  const router = useRouter();
  const currentLanguage = router.locale;

  const socialContainerClasses = classNames(styles.boxAllSocialMedia, {
    'grid grid-cols-2 md:grid-cols-4': ['ru'].includes(currentLanguage),
    'flex justify-between flex-wrap': !['ru'].includes(currentLanguage),
  });

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <h2 className={styles.title}>{data.title}</h2>
        <h3 className={styles.membersTitle}>{data.subTitle}</h3>
        <div className={socialContainerClasses}>
          <SocialMediaItem name='telegram' icon={<TelegramSocialSvg />} link={data.telegramLink} textBTN={data.joinButton} />
          <SocialMediaItem name='medium' icon={<MediumSvg />} link={data.mediumLink} textBTN={data.followButton} />
          <SocialMediaItem name='twitter' icon={<TwitterSvg />} link={data.twitterLink} textBTN={data.followButton} />
          <SocialMediaItem name='discord' icon={<DiscordSvg />} link={data.discordLink} textBTN={data.joinButton} />
        </div>
      </div>
    </div>
  );
};

export default SocialMediaTokenSale;
