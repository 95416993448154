import { createDirectus, registerUserVerify, rest, staticToken } from '@directus/sdk';

type Schema = {
  home_section: any[];
  battle_of_mars: any[];
  command_center_banner: any[];
  games_list: any[];
  games_slider: any[];
  twitter_list: any[];
  Faq: any[];
  faq_data: any[];
  Aboutus: any[];
  Partners: any[];
  Privacypolicy: any[];
  agreement: any[];
  Enduseragreement: any[];
  EndUserData: any[];
  Influencer_videos: any[];
};

const directus = createDirectus<Schema>(process.env.NEXT_PUBLIC_DIRECTUS_URL as string)
  .with(staticToken(process.env.NEXT_PUBLIC_DIRECTUS_TOKEN))
  .with(rest());

export default directus;
