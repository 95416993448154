import Wrapper from '@/components/TokenSale/Wrapper';
import { getPartners } from '@/lib/apis';
import { useEffect, useState } from 'react';
import PartnerSlider from '../PartnersSliderNew/PartnersSlider';

const CompanyPartnersLayout = (props) => {
  const [partners, setPartners] = useState<any>();

  useEffect(() => {
    getPartners().then((res) => {
      setPartners(res);
    });
  }, []);

  console.log('in company component', props, partners);

  return (
    <Wrapper>
      <PartnerSlider items={partners} title={props?.title} />
    </Wrapper>
  );
};

export default CompanyPartnersLayout;
