import React, { useEffect, useRef } from 'react';
import gsap from 'gsap';

const AnimatedButton = ({ buttonLink, buttonText, textColor, name }) => {
  const buttonRef = useRef(null);

  useEffect(() => {
    // Create GSAP animation for hover
    const buttonElement = buttonRef.current;

    const hoverAnimation = gsap.to(buttonElement, {
      y: -7, // Move 10px up
      duration: 0.3,
      paused: true,
      ease: 'power1.out',
    });

    // Add event listeners for hover and mouseout
    const handleMouseEnter = () => hoverAnimation.play();
    const handleMouseLeave = () => hoverAnimation.reverse();

    buttonElement.addEventListener('mouseenter', handleMouseEnter);
    buttonElement.addEventListener('mouseleave', handleMouseLeave);

    // Cleanup the event listeners when component unmounts
    return () => {
      buttonElement.removeEventListener('mouseenter', handleMouseEnter);
      buttonElement.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, []);

  return (
    <a
      style={{
        backgroundColor: textColor || 'white',
      }}
      href={buttonLink}
      ref={buttonRef}
      className={`px-3 py-2 xl:px-6 lg:py-3 min-w-[200px] flex justify-center font-semibold rounded-[4px] md:rounded-lg hover:opacity-80 transition-opacity gtm-download_now-${name.replace(
        ' ',
        '_',
      )}`}
    >
      {buttonText}
    </a>
  );
};

export default AnimatedButton;
