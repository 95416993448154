/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @next/next/no-img-element */
import React, { ReactNode, useEffect, useState } from 'react';
import { Progress, Tooltip } from '@material-tailwind/react';
import cn from 'classnames';
import classNames from 'classnames';
import Image from 'next/image';
import { useTranslate } from '@tolgee/react';
import { Typography } from '@/components/Atoms';
import { LockedSvg, MissedSvg, Share } from '@/components/Svg';
import FrameSvg from '@/components/Svg/FrameSvg';
import TimeLeftSvgTwo from '@/components/Svg/TimeLeftSvgTwo';
import YesSirSvg from '@/components/Svg/YesSirSvg';
import { ITaskDataType } from '@/types/Achievements';
import useCountdown from '@/utils/getDurationFormatted';
import { normalKey, specificKey } from './_taskCardList2';
import styles from './TaskCenterCard.module.scss';
import { STATE_CONDITIONS } from './TaskDetailModal/StateCinditions';
import CopyToClipboard from '@/utils/CopyToClipboard';

export const rewardConfig: {
  [key: string]: {
    name: string;
    color: string;
    icon?: ReactNode;
    currency?: string;
    image?: string;
    type_id?: string;
  };
} = {
  lrt: {
    color: '#FFCC00',
    name: 'LRT',
    image: '/images/tasks/reward-icons/LRT.svg',
    currency: 'LRT',
  },
  asset: {
    color: '#B443FF',
    name: 'Asset',
    image: '/images/tasks/reward-icons/Rover.svg',
    // icon: <RoversSvg height='100%' width='100%' />,
  },
  point: {
    color: '#00DCFF',
    name: 'Point',
    image: '/images/tasks/reward-icons/XP.svg',
    currency: 'Point',
  },
  xp: {
    color: '#00DCFF',
    name: 'XP',
    image: '/images/tasks/reward-icons/XP.svg',
    currency: 'XP',
  },
  material: {
    color: '#00DCFF',
    name: 'Material',
    image: '/images/tasks/reward-icons/element.svg',
    // icon: <MaterialsSvg height='100%' width='100%' />,
  },
  blueprint: {
    color: '#00DCFF',
    name: 'Blueprint',
    image: '/images/tasks/reward-icons/Bluprint.svg',
  },
  badge: {
    color: '#00DCFF',
    name: 'Badge',
    image: '/images/tasks/reward-icons/badge.svg',
  },
  fuel: {
    color: '#4BD359',
    name: 'Fuel',
    image: '/images/tasks/reward-icons/Fuel.svg',
    // icon: <FeulSvg height='100%' width='100%' />,
    currency: 'L',
  },
  second_fuel: {
    color: '#10B6FF',
    name: 'COSMA',
    image: '/images/tasks/reward-icons/cosma.png',
    currency: 'L',
  },
};

export interface TaskCenterCardProps extends ITaskDataType {
  bg_image?: string;
  onClick: (title: string, id: string, lock: boolean) => void;
  role?: boolean;
  idItem: string;
  number?: number;
  className?: string;
}

const gtmClassPrefix = 'gtm-command_center';

const TaskCenterCard2: React.FunctionComponent<TaskCenterCardProps> = ({
  id,
  Prizes,
  third_party_name,
  bg_image,
  start_time,
  end_time,
  onClick,
  role,
  achievement_approval,
  state,
  lock,
  idItem,
  available,
  className,
  ...props
  // eslint-disable-next-line sonarjs/cognitive-complexity
}) => {
  const isGame = props?.cats?.find((c) => c?.category_name === 'Game');
  const { t } = useTranslate('commandCenter');
  // const diffDateFormatted = useCountToDeadline(end_time);
  const diffDateFormatted = useCountdown(start_time > new Date().getTime() / 1000 ? start_time * 1000 : end_time * 1000 || 0, true);
  const currentTime = new Date().getTime() / 1000;
  const isLocked = start_time > new Date().getTime() / 1000 && diffDateFormatted !== '00:00:00';
  const isRemained = end_time > currentTime && currentTime > start_time && diffDateFormatted !== '00:00:00';
  const uniquePrizes = Prizes?.filter((item, index, self) => index === self.findIndex((t) => t.type === item.type));
  const [isMissed, setIsMissed] = useState(false);
  const findXP = uniquePrizes?.find((obj) => obj.type === 'xp');
  let categoryName = 'category_name';
  //@ts-ignore
  const isGolden = props?.cats?.some((obj) => obj[categoryName] === 'Golden');
  //@ts-ignore
  const isPrivate = props?.cats?.some((obj) => obj[categoryName] === 'Private');
  //@ts-ignore
  const isCorporal = props?.cats?.some((obj) => obj[categoryName] === 'Corporal');
  //@ts-ignore
  const isSergeant = props?.cats?.some((obj) => obj[categoryName] === 'Sergeant');
  //@ts-ignore
  const isLieutenant = props?.cats?.some((obj) => obj[categoryName] === 'Lieutenant');
  //@ts-ignore
  const isCaptain = props?.cats?.some((obj) => obj[categoryName] === 'Captain');

  const uniquePrizesWithoutXp = uniquePrizes?.filter((i) => i.type !== 'xp');
  const backgroundGradient = isGolden
    ? 'linear-gradient(0deg, rgba(78,56,0,1) 0%, rgba(238,175,13,1) 100%)'
    : 'linear-gradient(0deg, rgba(93,93,93,1) 0%, rgba(71,71,71,1) 100%)';

  useEffect(() => {
    if (!available) {
      setIsMissed(true);
    }
  }, [available]);

  useEffect(() => {
    const now = Math.floor(Date.now() / 1000);
    if (state !== 'completed' && end_time !== 0 && end_time < now) {
      setIsMissed(true);
    }
  }, [end_time]);

  const handleClickTask = () => {
    // if (isLocked) {
    //   return;
    // }
    onClick(props.description, id, lock);
  };
  const progress = Math.min((props?.user_action_count / props?.action_count) * 100, 100); //  0-100

  const stateIsValid = ['pending', 'completed', 'start', 'claimable'].includes(state);
  const StateIcon = stateIsValid ? STATE_CONDITIONS?.[state]?.icon : YesSirSvg;

  const tooltipContent =
    state === 'start' && role
      ? `${t('tooltips.publish')}`
      : lock
      ? `${t('tooltips.lock')}`
      : isMissed
      ? `${t('tooltips.missed')}`
      : stateIsValid
      ? // @ts-ignore
        `${t('tooltips.' + state)}`
      : `${t('tooltips.start')}`;

  return (
    <div className={classNames('relative w-full h-full flex justify-center')}>
      {props?.achievement_winner && (
        <>
          <div className='absolute inset-0 flex mt-8 justify-center overflow-hidden cursor-pointer ' onClick={handleClickTask}>
            <div className='z-10 flex flex-col items-center font-bold text-[#FFC400] text-shadow-xl shadow-black'>
              <Typography className='-mt-0.5' size='s20ToS16'>
                Winner
              </Typography>
              <Typography size='s26ToS16' className='truncate max-w-[270px]'>
                ID: {props.achievement_winner}
              </Typography>
            </div>
          </div>
        </>
      )}

      <div
        className={classNames(
          `${styles.wrapper} ${className} ${isMissed && 'grayscale '}`,
          role ? `${gtmClassPrefix}-commander-${id}` : `${gtmClassPrefix}-command-${id}`,
        )}
        style={{ background: backgroundGradient }}
        onClick={handleClickTask}
        id={idItem}
      >
        <div className='bg-[#152024] h-full rounded-[12px]'>
          <div className='absolute top-0 left-0 w-full z-10 p-[10px] flex justify-between items-start'>
            <div className='flex flex-col w-full'>
              {/* {isGolden && (
              <div className='w-fit px-1 rounded-lg' style={{ background: 'linear-gradient(90deg, rgba(228,192,97,1) 0%, rgba(177,133,18,1) 100%)' }}>
                <Typography isBold size='s18ToS14' className='line-height-1 text-[#5E4930]'>
                  Golden
                </Typography>
              </div>
            )} */}
              {isPrivate && (
                <div className='w-fit px-1 mt-1 rounded-lg flex items-center pt-[1px] pb-[3px]' style={{ background: ' rgba(196, 143, 108, 1)' }}>
                  <Typography isBold size='s14ToS12' className='text-[#171717]'>
                    Private
                  </Typography>
                </div>
              )}
              {isCorporal && (
                <div className='w-fit px-1 mt-1 rounded-lg flex items-center pt-[1px] pb-[3px]' style={{ background: 'rgba(159, 165, 166, 1)' }}>
                  <Typography isBold size='s14ToS12' className='text-[#242828]'>
                    Corporal
                  </Typography>
                </div>
              )}
              {isSergeant && (
                <div className='w-fit px-1 mt-1 rounded-lg flex items-center pt-[1px] pb-[3px]' style={{ background: 'rgba(248, 217, 119, 1)' }}>
                  <Typography isBold size='s14ToS12' className='text-[#533300]'>
                    Sergeant
                  </Typography>
                </div>
              )}
              {isLieutenant && (
                <div className='w-fit px-1 mt-1 rounded-lg flex items-center pt-[1px] pb-[3px]' style={{ background: 'rgba(186, 255, 212, 1)' }}>
                  <Typography isBold size='s14ToS12' className='text-[#3A4800]'>
                    Lieutenant
                  </Typography>
                </div>
              )}
              {isCaptain && (
                <div className='w-fit px-1 mt-1 rounded-lg flex items-center pt-[1px] pb-[3px]' style={{ background: 'rgba(153, 178, 255, 1)' }}>
                  <Typography isBold size='s14ToS12' className='text-[#002D5A]'>
                    Captain
                  </Typography>
                </div>
              )}
              <div className='flex items-center gap-3 pe-3'>
                {(achievement_approval?.length > 0 || third_party_name) && (
                  <div className='flex items-center gap-1'>
                    {achievement_approval && achievement_approval.length > 1 ? (
                      <>
                        <div className='w-[28px] h-[31px]'>
                          <Image src={'/images/tasks/captainSvg.svg'} width={28} height={31} layout='responsive' alt='captain-svg' />
                        </div>
                        <Typography size='s12ToS12'>Commanders X {achievement_approval.length}</Typography>
                      </>
                    ) : (
                      <>
                        <div className='w-[28px] h-[31px]'>
                          <Image src={'/images/tasks/captainSvg.svg'} width={28} height={31} layout='responsive' alt='captain-svg' />
                        </div>
                        <Typography size='s12ToS12'>{achievement_approval[0].user_name}</Typography>
                      </>
                    )}
                  </div>
                )}
              </div>
              <div className='flex gap-1 items-center h-[20px] mt-1 w-auto'>
                {/* @ts-ignore */}
                {/* {props?.cats?.map((item, index) => (
                <Image src={item.icon} key={index} layout='fixed' width={'18px'} height={'18px'} alt='category-image' />
              ))} */}
              </div>
            </div>

            {!lock && start_time < new Date().getTime() / 1000 && (
              <div className='flex flex-col w-fit rounded-[10px] justify-center items-center '>
                {/* {filteredPrizes?.length > 0 && ( */}
                {!isMissed && (
                  <>
                    <div className=' z-10 my-1 flex justify-center items-center overflow-hidden rounded-full'>
                      <span className='relative flex h-5 w-5'>
                        <span
                          className='absolute inline-flex h-full w-full rounded-full bg-[#12DB00] opacity-75 animate-ping '
                          style={{ animationDuration: '2s' }}
                        ></span>
                        <span className='relative inline-flex rounded-full h-3 w-3 bg-[#12DB00] m-auto'></span>
                      </span>
                    </div>
                  </>
                )}
                <div className='flex flex-row justify-center items-center bg-[#282828] bg-opacity-80 p-1 rounded-md'>
                  <div
                    onClick={(e) => {
                      e.stopPropagation();
                      CopyToClipboard(process.env.NEXT_PUBLIC_HOSTNAME + `command-center?${role ? specificKey : normalKey}=${id}`, {
                        successMessageKey: 'Link successfully copied.',
                      });
                    }}
                  >
                    <Share height={15} width={20} color='white' />
                  </div>
                  {/* <SaveSvg height={15} width={20} color='#B8B8B8'/>
              <LikeSvg height={15} width={20} color='#B8B8B8'/> */}
                  {/* <div className='mt-1'>
                <DotMenuSvg height={17} width={19} color='#B8B8B8' />
              </div> */}
                </div>
              </div>
            )}

            {/* Category Icon */}
            {/* <div className='flex flex-col px-1 mt-2'>
          <img alt='icon-category' className='w-4 h-4 object-contain' src={icon} />
        </div> */}
          </div>

          {bg_image ? (
            <div className='relative flex items-end pb-4 ps-4 rounded-t-[15px] h-[143px] w-full'>
              <Image
                src={bg_image}
                alt='image not loaded'
                layout='fill'
                objectFit='cover'
                className={cn('rounded-t-[15px] h-[143px]', isLocked && 'blur-sm')} // Optional: Add any additional styles if needed
              />
              <div
                className='absolute bottom-0 left-0 w-full h-full rounded-t-[15px]'
                style={{ background: ' linear-gradient(0deg, rgba(21,32,36,1) 0%, rgba(21,32,36,0) 50%)' }}
              ></div>

              {isLocked && (
                <>
                  <div className='absolute inset-0 bg-gradient-to-b rounded-xl from-[rgba(30,24,38,1)] to-[rgba(17,18,23,0)]'></div>

                  <div className='absolute inset-0 flex items-center justify-center'>
                    <div className='flex flex-col items-center font-bold bg-gradient-to-r to-[#12DB00] from-[#CEBF38] text-transparent bg-clip-text'>
                      <Typography size='s28ToS18'>{diffDateFormatted}</Typography>
                      <Typography className='-mt-0.5' size='s20ToS16'>
                        To Activation
                      </Typography>
                    </div>
                  </div>
                </>
              )}
              {/* {props?.achievement_winner && (
              <>
                <div className='absolute z-100 inset-0 bg-gradient-to-b from-[rgba(30,24,38,1)] to-[rgba(17,18,23,0)] !filter-none'></div>

                <div className='absolute inset-0 flex items-center justify-center filter-none'>
                  <div className='z-[10000] flex flex-col items-center font-bold bg-gradient-to-r to-[#12DB00] from-[#CEBF38] text-transparent bg-clip-text'>
                    <Typography size='s28ToS18'>ID: {props.achievement_winner}</Typography>
                    <Typography className='-mt-0.5' size='s20ToS16'>
                      Winner
                    </Typography>
                  </div>
                </div>
              </>
            )} */}
            </div>
          ) : null}
          <div className='px-2 pb-3 flex flex-col justify-between h-[calc(100%-143px)]'>
            <div className='flex flex-col'>
              <div className={styles.cardHeader}>
                <div className='flex flex-col mb-2 mt-1'>
                  <Typography size='s18ToS14' isBold>
                    {props.name}
                  </Typography>
                </div>
              </div>
              {progress > 0 && (
                <div className='w-full flex flex-row items-center gap-2'>
                  <Progress
                    value={progress}
                    className='border-[#3D3D3D] border bg-[#2B2B2B] rounded-[3px] h-[8px]'
                    color='green'
                    placeholder={'fd'}
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                  />
                  <div className='flex items-center justify-between gap-4'>
                    <Typography size='s12ToS12' className='text-[#31DA60]'>{`${Math.floor(progress)}%`}</Typography>
                  </div>
                </div>
              )}
              <Tooltip
                content={t('tooltips.reward')}
                animate={{
                  mount: { scale: 1, y: 0 },
                  unmount: { scale: 0, y: 25 },
                }}
                className='max-w-[350px]'
                placeholder=''
                onPointerEnterCapture={undefined}
                onPointerLeaveCapture={undefined}
              >
                <div className='w-full flex flex-row items-center justify-between font-bold text-[#FFC400]'>
                  <Typography size='s16ToS14'>{t('CENTER_SECTION.achievementSection.reward')}</Typography>
                  {props.number && <Typography size='s16ToS14'>ID:{props.number}</Typography>}
                </div>
              </Tooltip>
              <div className='bg-[#93939321] rounded-[6px] px-[9px] flex flex-col w-full'>
                {uniquePrizesWithoutXp?.map((item, index) => {
                  const itemTypeLowerCase = item.type.toLowerCase(); // Ensure type is in lowercase
                  const reward = rewardConfig[itemTypeLowerCase];
                  return reward ? (
                    <div key={index} className='flex flex-col'>
                      <div className='flex justify-between items-center w-full h-9'>
                        <div className='flex gap-x-1 items-center'>
                          <div className='w-6 h-6'>
                            {item.img ? (
                              <img src={item.img} alt={item.type} className='w-6 h-6 object-contain' />
                            ) : reward.icon ? (
                              reward.icon
                            ) : (
                              <img src={reward.image} alt={item.type} className='w-6 h-6 object-contain' />
                            )}
                          </div>
                          {/* <Typography size='s12ToS12' className='-mb-1'>
                            +
                          </Typography> */}
                          <Typography size='s14ToS12'>{item.amount}</Typography>
                          <Typography size='s14ToS12'>{item?.reward_name || reward?.name || ''}</Typography>
                        </div>
                      </div>
                      {uniquePrizesWithoutXp.length - 1 > index && <div className='w-full bg-[#424242] h-[1px]'></div>}
                    </div>
                  ) : null;
                })}
                {findXP?.amount !== undefined && (
                  <>
                    <div className='w-full bg-[#424242] h-[1px]'></div>
                    <div className='flex items-center gap-2 px-[4px] py-[6px]'>
                      <img src='/images/tasks/reward-icons/XP.svg' className='w-6 h-6' />
                      <Typography size='s14ToS12'>{findXP?.amount}</Typography>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className={styles.cardFooter}>
              <div className='flex flex-col justify-between items-center'>
                <div className='flex flex-row justify-between w-full mt-2 items-center -mb-1'>
                  <div className='flex flex-col'>
                    {props?.Report?.pool > 0 && props.Report?.count > 0 && (
                      <div className='flex items-center gap-0 lg:gap-1'>
                        <FrameSvg color={'#ffffff'} width={20} />
                        <Typography size='s12ToS12' className='text-[#31DA60]'>
                          {`${props.Report.count}/${props.Report.pool}`}
                        </Typography>
                      </div>
                    )}
                    {isRemained && (
                      <div className='flex items-center gap-0 lg:gap-1'>
                        <TimeLeftSvgTwo color={'#ffffff'} width={20} />
                        <Typography size='s12ToS12' className='text-[#31DA60] text-nowrap'>
                          <div>{diffDateFormatted} Left</div>
                        </Typography>
                      </div>
                    )}
                  </div>
                  <Tooltip
                    content={tooltipContent}
                    animate={{
                      mount: { scale: 1, y: 0 },
                      unmount: { scale: 0, y: 25 },
                    }}
                    className='max-w-[350px]'
                    placeholder=''
                    onPointerEnterCapture={undefined}
                    onPointerLeaveCapture={undefined}
                  >
                    {isGame ? (
                      <div className='border-[#00F0FF] text-[#00C9E4] flex max-h-7 h-full justify-center items-center  border rounded-[4px] gap-1 py-2 px-2 md:px-4 min-w-[70px]'>
                        <StateIcon />
                        <Typography size='s12ToS12' isBold>
                          {t('CENTER_SECTION.achievementSection.button.confirm')}
                        </Typography>
                      </div>
                    ) : (
                      <div
                        className={`flex max-h-7 h-full justify-center items-center  border rounded-[4px] gap-1 py-2 px-2 md:px-4 min-w-[70px]  ${
                          lock ? STATE_CONDITIONS?.lock?.bg : stateIsValid ? STATE_CONDITIONS?.[state]?.bg : 'bg-rgba(10, 80, 84, 0.48)'
                        } ${lock ? STATE_CONDITIONS?.lock?.border : stateIsValid ? STATE_CONDITIONS?.[state]?.border : 'border-[#00F0FF]'} ${
                          lock ? STATE_CONDITIONS?.lock?.textColor : stateIsValid ? STATE_CONDITIONS?.[state]?.textColor : 'text-[#00C9E4]'
                        }`}
                      >
                        <div className='mr-1'>{lock ? <LockedSvg /> : isMissed ? <MissedSvg /> : <StateIcon />}</div>
                        <Typography size='s12ToS12' isBold>
                          {state == 'start' && role
                            ? 'Publish'
                            : lock
                            ? 'Locked'
                            : isMissed
                            ? `${t('CENTER_SECTION.achievementSection.button.missed')}`
                            : stateIsValid
                            ? t(`${STATE_CONDITIONS?.[state]?.text}` as any)
                            : t('CENTER_SECTION.achievementSection.button.confirm')}
                        </Typography>
                      </div>
                    )}
                  </Tooltip>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskCenterCard2;
