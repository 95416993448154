export const PAGES: Record<string, 'isWide' | 'isNarrow' | 'none'> = {
  '/about-us': 'isNarrow',
  '/achievements': 'isWide',
  '/api': 'isNarrow',
  '/avatar-maker': 'isWide',
  '/bitcoin-hunt': 'isWide',
  '/buy-lrt': 'none',
  '/blog': 'isWide',
  '/blueprint': 'isWide',
  '/bug-report': 'isNarrow',
  '/builder': 'isWide',
  '/calling-all-influencers': 'isNarrow',
  '/choose-avatar': 'isNarrow',
  '/command-center': 'none',
  '/contact-us': 'isNarrow',
  '/breaking-news': 'isWide',
  '/daily-updates': 'isNarrow',
  '/dashboard': 'isWide',
  '/end-user-agreement': 'isNarrow',
  '/faq': 'isNarrow',
  '/finance': 'isWide',
  '/finance2': 'isNarrow',
  '/game': 'none',
  '/game/download': 'none',
  '/game-connect': 'isNarrow',
  '/game-economy': 'isWide',
  '/global-stats': 'isWide',
  '/how-to-play-mine-earn': 'none',
  '/inventory': 'isWide',
  '/kitblock': 'isNarrow',
  '/kitblock-landing': 'isWide',
  '/land': 'isWide',
  '/lite-game': 'isWide',
  '/lite-game-access': 'isNarrow',
  '/mail-box': 'isNarrow',
  '/map': 'isWide',
  '/marketplace': 'isNarrow',
  '/news': 'isWide',
  '/official-verify': 'isNarrow',
  '/press': 'isNarrow',
  '/privacy-policy': 'isNarrow',
  '/private-sale-agreement': 'isNarrow',
  '/profile': 'isWide',
  '/purchase-history': 'isNarrow',
  '/roadmap': 'isWide',
  '/room': 'isNarrow',
  '/services': 'isWide',
  '/stake': 'isWide',
  '/start-mining': 'isWide',
  '/support': 'isNarrow',
  '/swap': 'isNarrow',
  '/tasks': 'isNarrow',
  '/token-sale': 'isWide',
  '/white-paper': 'isNarrow',
  '/events': 'none',
  '/': 'none',
  '/test': 'none',
  '/telegram': 'none',
  '/ambassador': 'none',
  '/ambassador/submit': 'none',
  '/battle-of-rovers': 'none',
  // Add other pages here as needed
};
