import React, { useState } from 'react';
import { useTranslate } from '@tolgee/react';
import { useResponsive } from '@/hooks';
import SectionContainer from '../../Molecules/SectionContainer';
import { Typography } from '@/components/Atoms';
import Link from 'next/link';
import { HomeSectionProps } from '../HeroSection';
import { getDirectusImageUrl } from '@/utils/getUrl';

export interface CryptoHuntProps {}

const CryptoHunt: React.FunctionComponent<HomeSectionProps> = (props) => {
  const { t } = useTranslate('newHome');
  const { isDesktop1024 } = useResponsive();

  return (
    <SectionContainer
      imageXlMinW='600px'
      title={t(props.title)}
      subTitle={t(props.subtitle)}
      description={t(props.description)}
      backgroundImage={getDirectusImageUrl(props.backgroundImage)}
      mobileImage={getDirectusImageUrl(props.bgMob)}
      subTitleSize='s28ToS15'
      hasLine
      contentPosition='left'
      height={!isDesktop1024 ? '700px' : '400px'}
      buttons={
        <a
          href={props.linkButtonOne}
          target='_blank'
          rel='noopener noreferrer'
          className='gtm-crypto-section  px-2 md:px-4 py-2 min-w-[200px] flex items-center justify-center rounded-[4px] md:rounded-lg shadow-xl border border-[#63FEBC] bg-[#63FEBC] font-bold text-black button-hover-effect'
        >
          <Typography size='s20ToS16'>{t(props.nameButtonOne)}</Typography>
        </a>
      }
      imageUrl={isDesktop1024 ? undefined : getDirectusImageUrl(props.image)}
      imageClasses='mt-[50px]'
    />
  );
};

export default CryptoHunt;
