/* eslint-disable arrow-body-style */
/* eslint-disable jsx-a11y/alt-text */
import React from 'react';
import classNames from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslate } from '@tolgee/react';
import SectionHeader from '../../Molecules/SectionHeader';
import styles from '../index.module.scss';
import { HomeSectionProps } from '../HeroSection';
import { getDirectusImageUrl } from '@/utils/getUrl';

const AuditedBySection: React.FunctionComponent<HomeSectionProps> = (props) => {
  const { t } = useTranslate();
  const { pathname } = useRouter();
  const isBuyLrt = pathname !== '/buy-lrt';

  return (
    <div className={classNames({ [styles.gradient_audit_box]: isBuyLrt }, 'px-4 pt-1 md:px-20 lg:px-24 xl:px-44 2xl:px-72 pb-10 lg:pb-20')}>
      <SectionHeader title={t(props.title)} />
      <div className={classNames('grid grid-cols-3 lg:grid-cols-4 grid-rows-2 gap-2 lg:gap-4', styles.container)}>
        {props?.details?.length > 0 &&
          props?.details?.map((image, index) => {
            return (
              <div className='mb-4 lg:mb-0 mx-auto' key={index}>
                <Link href={image.href} passHref={true}>
                  <a target='_blank' id='whitepaper-btn'>
                    <div className='hover:scale-105 transition-transform'>
                      <Image src={getDirectusImageUrl(image.imageId)} objectFit='contain' width={207} height={64} alt='audited' />
                    </div>
                  </a>
                </Link>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default AuditedBySection;
