/* eslint-disable i18next/no-literal-string */
import React, { useEffect, useRef, useState } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
// Trigger-build
import axios from 'axios';
import classnames from 'classnames';
import { useTranslate } from '@tolgee/react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import LoadingBalls from '@/components/Common/LoadingBalls';
import { ArrowLeftSvg, ArrowRightSvg, LRLogoSvg, VerifiedGoldStarSvg } from '@/components/Svg';
import XSvg from '@/components/Svg/XSvg';
import Wrapper from '@/components/TokenSale/Wrapper';
import { Tweet } from '@/pages/api/fetchTweets';
import TweetCard from '../TweetCard/TweetCard';
import styles from './TweetsWrapper.module.scss';
import { getTweetList } from '@/lib/apis';
import useData from '@/components/Home/DailyNews/useData';
import { HomeSectionProps } from '@/components/NewHome/HeroSection';

const TweetsWrapper = ({ title, nameButtonOne }: HomeSectionProps) => {
  const { t } = useTranslate();
  const [data, setData] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const swiperRef = useRef(null);
  const { breakpoints } = useData();

  const extractIdsAndConvertToString = (data) => {
    if (!Array.isArray(data)) {
      return '';
    }

    return data.map((item) => item.id).join(',');
  };
  const fetchTweets = async () => {
    const Tweets = await getTweetList();
    const idList = extractIdsAndConvertToString(Tweets?.[0]?.ids as Array<any>);

    try {
      const res = await axios.get<{ data: Tweet[] }>(`/api/fetchTweets?username=landrocker_io&ids=${idList}`);
      setData(res.data.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchTweets();
  }, []);
  const handlePrevClick = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNextClick = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  return (
    <Wrapper>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          <div className={styles.headerTitle}>
            <div className='capitalize'>{!title ? t('twitter.title') : t(title)}</div>
            <XSvg classnames='block md:hidden' width={30} />
            <XSvg classnames='hidden md:block xl:hidden' width={45} />
            <XSvg classnames='hidden xl:block' width={60} />
          </div>
          <div className={styles.headerStrip}>
            {/* start section logo handler */}
            <div className='relative flex gap-3'>
              <LRLogoSvg classnames='block md:hidden' width={40} />
              <LRLogoSvg classnames='hidden md:block xl:hidden' width={55} />
              <LRLogoSvg classnames='hidden xl:block' width={55} />
              <div className='flex flex-col justify-center'>
                <div className='flex gap-[8px] items-center'>
                  <span className='font-bold text-[18px] md:text-[24px]'>LandRocker</span>
                  <VerifiedGoldStarSvg width={23.77} />
                </div>
                <div className='text-[#848484] font-normal text-[16px] md:text-[20px]'>@landrocker_io</div>
              </div>
            </div>
            <div className='flex items-center justify-center'>
              <a
                className={styles.followBtn}
                href='https://twitter.com/intent/follow?original_referer=http%3A%2F%2Flocalhost%3A3000%2F&ref_src=twsrc%5Etfw%7Ctwcamp%5Ebuttonembed%7Ctwterm%5Efollow%7Ctwgr%5Elandrocker_io&region=follow_link&screen_name=landrocker_io'
                target='_blank'
                rel='noopener noreferrer'
              >
                {!nameButtonOne ? t('join_us.buttons.FOLLOW') : t(nameButtonOne)}
              </a>
            </div>
          </div>
        </div>
        <div className='w-full mt-4'>
          {isLoading ? (
            <div className='mt-[50px]'>
              <LoadingBalls />
            </div>
          ) : (
            <>
              <Swiper
                className='mySwiper'
                modules={[Autoplay, Pagination, Navigation]}
                onInit={(swiper) => {
                  setIsBeginning(swiper.isBeginning);
                  setIsEnd(swiper.isEnd);
                }}
                onReachBeginning={() => setIsBeginning(true)}
                onReachEnd={() => setIsEnd(true)}
                onFromEdge={() => {
                  setIsBeginning(false);
                  setIsEnd(false);
                }}
                pagination={{
                  dynamicBullets: true,
                  clickable: true,
                }}
                navigation={{
                  // eslint-disable-next-line sonarjs/no-duplicate-string
                  prevEl: `.${styles['swiper-button-prev']}`,
                  // eslint-disable-next-line sonarjs/no-duplicate-string
                  nextEl: `.${styles['swiper-button-next']}`,
                }}
                breakpoints={breakpoints}
                ref={swiperRef}
              >
                {data?.map((tweet, idx: number) => (
                  <SwiperSlide key={idx} className='w-auto'>
                    <TweetCard text={tweet.text} id={tweet.id} public_metrics={tweet.public_metrics} media={tweet.media || []} />
                  </SwiperSlide>
                ))}
              </Swiper>
              {!isBeginning && (
                <div className={classnames(styles['swiper-button-prev'], 'swiper-button-prev')} onClick={handlePrevClick}>
                  <ArrowLeftSvg color='black' />
                </div>
              )}
              {!isEnd && (
                <div className={classnames(styles['swiper-button-next'], 'swiper-button-next')} onClick={handleNextClick}>
                  <ArrowRightSvg color='black' />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </Wrapper>
  );
};

export default TweetsWrapper;
