import React, { useEffect, useState } from 'react';
import { useTranslate } from '@tolgee/react';
import SectionContainer from '../../Molecules/SectionContainer';
import { HomeSectionProps } from '../HeroSection';
import { getDirectusImageUrl } from '@/utils/getUrl';

export interface HistorySectionProps {}

const HistorySection: React.FunctionComponent<HomeSectionProps> = (props) => {
  const { t } = useTranslate();
  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  return (
    <SectionContainer
      title={t(props.title)}
      description={t(props.description)}
      backgroundImage={getDirectusImageUrl(props.backgroundImage)}
      hasAnimation={false}
      contentPosition='left'
      buttons={null}
      descColor='white'
      imageUrl={getDirectusImageUrl(props.image)}
      imageClasses='bottom-0 h-full'
      imagePosition='bottom'
      mt={150}
      titleStyle={{
        background: 'linear-gradient(90deg, #FFFFFF, #A5A7B8)' /* Adjust the colors and angle as needed */,
        WebkitTextFillColor: 'transparent',
        WebkitBackgroundClip: 'text',
        backgroundClip: 'text',
        textFillColor: 'transparent',
      }}
    />
  );
};

export default HistorySection;
