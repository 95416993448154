/* eslint-disable i18next/no-literal-string */
import { FC, useState } from 'react';
import { Tooltip } from '@material-tailwind/react';
import { LRLogoSvg, RetweetXSvg, TweetEyeSvg, TweetHeartSvg, TweetMessageSvg, TweetShareSvg, VerifiedGoldStarSvg } from '@/components/Svg';
import { ctaGenerator } from './ctaGenerator';
import styles from './TweetCard.module.scss';

type PublicMetrics = {
  bookmark_count?: number;
  impression_count: number;
  like_count: number;
  quote_count: number;
  reply_count: number;
  retweet_count: number;
};

type Media = {
  type: string;
  url?: string;
  preview_image_url?: string;
};

interface ComponentProps {
  id: string;
  text: string;
  public_metrics: PublicMetrics;
  media?: Media[]; // Media prop to include videos and photos
}

const TweetCard: FC<ComponentProps> = ({ id, text, public_metrics, media }) => {
  const { impression_count, like_count, quote_count, reply_count, retweet_count } = public_metrics;
  const [activeVideoIndex, setActiveVideoIndex] = useState(null);

  return (
    <div className={styles.wrapper}>
      {/* Media Section */}
      {media && media.length > 0 && (
        <div className='mb-4'>
          {media.map((item, index) => {
            switch (item.type) {
              case 'video':
                return (
                  <div key={index} className='w-full rounded-lg overflow-hidden'>
                    {activeVideoIndex === index ? (
                      <video className='w-full rounded-lg' controls autoPlay poster={item.preview_image_url || undefined}>
                        <source src={item.url} type='video/mp4' />
                        Your browser does not support the video tag.
                      </video>
                    ) : (
                      <div className='relative'>
                        <div className={`${styles.circle}`}>
                          <div className={styles.triangle} />
                        </div>
                        <img
                          src={item.preview_image_url}
                          alt='Video Poster'
                          className='w-full rounded-lg cursor-pointer'
                          onClick={() => setActiveVideoIndex(index)}
                        />
                      </div>
                    )}
                  </div>
                );
              case 'photo':
                return <img key={index} src={item.url} alt='Tweet Media' className='w-full rounded-lg' loading='lazy' />;
              default:
                return null;
            }
          })}
        </div>
      )}

      {/* Tweet Content */}
      <a className={styles.cardContent} href={ctaGenerator(id, 'view')} target='_blank' rel='noopener noreferrer'>
        <>
          <div className={styles.cardHeader}>
            <div className={styles.startSection}>
              <div className={styles.logo}>
                <LRLogoSvg classnames='block md:hidden' width={24} />
                <LRLogoSvg classnames='hidden md:block xl:hidden' width={28} />
                <LRLogoSvg classnames='hidden xl:block' width={32} />
              </div>
              <div className={styles.title}>LandRocker</div>
              <VerifiedGoldStarSvg classnames='block md:hidden' width={14} />
              <VerifiedGoldStarSvg classnames='hidden md:block xl:hidden' width={18} />
              <VerifiedGoldStarSvg classnames='hidden xl:block' width={20} />
            </div>
            <div className={styles.endSection}>@landrocker_io</div>
          </div>
          <div className={styles.cardMiddle}>{text}</div>
        </>
      </a>

      <a href={ctaGenerator(id, 'view')} target='_blank' rel='noopener noreferrer'>
        <div className='relative text-right text-[#1E80FF] w-full h-auto mb-1'>...Read all</div>
      </a>

      {/* Metrics Section */}
      <div className={styles.cardFooter}>
        <Tooltip content='Impressions' className='!bg-[#404040]' placeholder='' onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
          <a className={styles.bottomCta} href={ctaGenerator(id, 'view')} target='_blank' rel='noopener noreferrer'>
            <TweetEyeSvg />
            <span>{impression_count}</span>
          </a>
        </Tooltip>
        <Tooltip content='Like' className='!bg-[#404040]' placeholder='' onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
          <a className={styles.bottomCta} href={ctaGenerator(id, 'like')} target='_blank' rel='noopener noreferrer'>
            <TweetHeartSvg />
            <span>{like_count}</span>
          </a>
        </Tooltip>
        <Tooltip content='Message' className='!bg-[#404040]' placeholder='' onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
          <a className={styles.bottomCta} href={ctaGenerator(id, 'quote')} target='_blank' rel='noopener noreferrer'>
            <TweetMessageSvg />
            <span>{quote_count}</span>
          </a>
        </Tooltip>
        <Tooltip content='Repost' className='!bg-[#404040]' placeholder='' onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
          <a className={styles.bottomCta} href={ctaGenerator(id, 'repost')} target='_blank' rel='noopener noreferrer'>
            <RetweetXSvg />
            <span>{retweet_count}</span>
          </a>
        </Tooltip>
        <Tooltip content='Reply' className='!bg-[#404040]' placeholder='' onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
          <a className={styles.bottomCta} href={ctaGenerator(id, 'reply')} target='_blank' rel='noopener noreferrer'>
            <TweetShareSvg />
            <span>{reply_count}</span>
          </a>
        </Tooltip>
      </div>
    </div>
  );
};

export default TweetCard;
