import React, { useEffect, useRef, useState } from 'react';
import Hamburger from 'hamburger-react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useSession } from 'next-auth/react';
import { useAccount } from 'wagmi';
import { Typography } from '@/components/Atoms';
import { HelpSvg, LogoSSvg, LRSvg } from '@/components/Svg';
import AvatarLoginSvg from '@/components/Svg/AvatarLoginSvg';
import ConnectedWalletSvg from '@/components/Svg/ConnectedWalletSvg';
import WalletSvg from '@/components/Svg/WalletSvg';
import { EAuthVarient } from '@/types/Authentication';
import { useStore } from '@/zustand/store/store';
import CustomSkeleton from '../CustomSkeleton';
import HelpCenter from '../HelpCenter';
import Profile from '../Profile';
import WalletInfo from '../WalletInfo';
import styles from './Header.module.scss';
import HeaderSubLinks from './HeaderSubLinks';
import LanguageSvg from '@/components/Svg/LanguageSvg';
import classNames from 'classnames';
import IconMenu from './IconMenu';
import { useTranslate } from '@tolgee/react';
import dynamic from 'next/dynamic';

interface IHeaderProps {
  toggled: boolean;
  toggle: (item: boolean) => void;
}

const ChangeLanguageDynamic = dynamic(() => import('@/components/Molecules/ChangeLanguage'), { ssr: false });

const Header: React.FC<IHeaderProps> = ({ toggled, toggle }) => {
  const { t } = useTranslate('header');
  const router = useRouter();
  const { setAuthModalVarient, setNotifier, notifier } = useStore();
  const session = useSession();
  const { address } = useAccount();
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    setIsReady(true);
  }, []);

  // Handlers to open modals
  const toggleDrawer = () => (toggled ? toggle(false) : toggle(true));

  useEffect(() => {
    if (router.pathname === '/mail-box') {
      setNotifier({ ...notifier, newMail: false });
    }
  }, [router, setNotifier]);
  return (
    <header className={styles.header}>
      <div className={styles.boxHeader}>
        {/* Logo */}
        <div className='flex gap-7 items-center'>
          <Link href='/'>
            <a>
              <div className='hidden xl:flex'>
                <LRSvg />
              </div>
              <div className='flex xl:hidden'>
                <LogoSSvg />
              </div>
            </a>
          </Link>
        </div>
        {/* Header Links */}
        <HeaderSubLinks />
        {/* Buttons (Profile, Wallet, Help) */}
        <div className={styles.btns}>
          {/* Buy LRT Button */}
          <Link href={'/buy-lrt'} passHref>
            <a>
              <div className={classNames(styles.btnLRT, 'gtm-click-buy-lrt-top-bar')}>
                <div className={styles.btnLRTChild}>
                  <Typography size='s16ToS12'>{t('buy')}</Typography>
                </div>
              </div>
            </a>
          </Link>

          {/* Profile Button */}
          {session.status === 'loading' ? (
            <CustomSkeleton width={20} height={20} />
          ) : session.status === 'authenticated' ? (
            <IconMenu
              icon={
                <div className='relative'>
                  {notifier.newMail && <div className='header_notifier_badge absolute -top-1 -left-1'></div>}
                  <AvatarLoginSvg color={'#0F9918'} />
                </div>
              }
            >
              <Profile data={session?.data?.user as any} />
            </IconMenu>
          ) : (
            <div className={classNames(styles.buttonConnectWalletTwo)}>
              <Typography size='s16ToS12' onClick={() => setAuthModalVarient(EAuthVarient.SIGN_IN)} className={styles.signup}>
                {t('login')}
              </Typography>
            </div>
          )}

          {/* Wallet Button */}
          {isReady && (
            <IconMenu icon={address ? <ConnectedWalletSvg /> : <WalletSvg className='h-14' />}>
              <WalletInfo />
            </IconMenu>
          )}

          {/* Language Switcher */}
          <IconMenu
            icon={
              <div className='h-14 flex items-center'>
                <LanguageSvg />
              </div>
            }
          >
            <ChangeLanguageDynamic />
          </IconMenu>

          {/* Help Center Button */}
          <IconMenu icon={<HelpSvg className='h-14' />}>
            <HelpCenter />
          </IconMenu>

          {/* Hamburger Menu (Drawer) */}
          <div className='flex lg:hidden'>
            <Hamburger toggled={toggled} toggle={toggleDrawer} color={`${toggled ? '#29d7df' : 'white'}`} size={24} />
          </div>
        </div>
      </div>
    </header>
  );
};

export default React.memo(Header);
