const useData = () => {
  const breakpoints = {
    360: {
      slidesPerView: 1,
    },

    601: {
      slidesPerView: 1.6,
      spaceBetween: 10,
    },
    705: {
      slidesPerView: 1.8,
      spaceBetween: 10,
    },
    768: {
      slidesPerView: 1.8,
      spaceBetween: 40,
    },
    817: {
      slidesPerView: 2.2,
      spaceBetween: 40,
    },
    1024: {
      slidesPerView: 2.5,
      spaceBetween: 20,
    },
    1204: {
      slidesPerView: 2.7,
      spaceBetween: 20,
    },
    1440: {
      slidesPerView: 3.2,
      spaceBetween: 20,
    },
    1600: {
      slidesPerView: 3.6,
      spaceBetween: 20,
    },
    1920: {
      slidesPerView: 2.8,
      spaceBetween: 29,
    },
  };

  return { breakpoints };
};

export default useData;
