import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import styles from './styles.module.scss';

// import required modules
import { Autoplay, EffectFade, Navigation, Pagination } from 'swiper/modules';
import BitcoinSection from './BitcoinSection';
import BattleOfRoversSection from './BattleOfRoversSection/inedex';
import CraftSection from './CraftSection/inedex';
import { HomeSectionProps } from '../HeroSection';
import GalaxySection from './GalaxySection/inedex';

export interface HomeSwiperSectionProps extends HomeSectionProps {}

const HomeSwiperSection: React.FunctionComponent<HomeSwiperSectionProps> = (props) => {
  // const { breakpoints } = useData();
  const [isBeginning, setIsBeginning] = useState(true);
  const [isEnd, setIsEnd] = useState(false);
  const swiperRef = useRef(null);

  const handlePrevClick = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleNextClick = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  if (!props) return null;

  return (
    <div className='overflow-hidden'>
      <Swiper
        autoplay={{
          delay: 5000,
          // disableOnInteraction: true,
        }}
        // effect='fade'
        speed={5000}
        className='mySwiper'
        modules={[Autoplay, Pagination, Navigation, EffectFade]}
        onInit={(swiper) => {
          setIsBeginning(swiper.isBeginning);
          setIsEnd(swiper.isEnd);
        }}
        onReachBeginning={() => setIsBeginning(true)}
        onReachEnd={() => setIsEnd(true)}
        onFromEdge={() => {
          setIsBeginning(false);
          setIsEnd(false);
        }}
        pagination={{
          dynamicBullets: true,
          clickable: true,
        }}
        navigation={{
          // eslint-disable-next-line sonarjs/no-duplicate-string
          prevEl: `.${styles['swiper-button-prev']}`,
          // eslint-disable-next-line sonarjs/no-duplicate-string
          nextEl: `.${styles['swiper-button-next']}`,
        }}
        // breakpoints={breakpoints}
        ref={swiperRef}
        loop={true}
      >
        {/* <SwiperSlide>
          <GalaxySection data={props.details?.[3]?.data[0]} aboveTitle={props.details?.[3]?.title} imageId={props.details?.[3]?.imageId} />
        </SwiperSlide> */}
        <SwiperSlide>
          <CraftSection data={props.details[0].data[0]} imageId={props.details?.[0]?.imageId} mobileImageId={props.details?.[0]?.mobile_image_id} />
        </SwiperSlide>
        <SwiperSlide>
          <BattleOfRoversSection data={props.details[1].data[0]} />
        </SwiperSlide>
        <SwiperSlide>
          <BitcoinSection data={props.details[2].data[0]} imageId={props.details?.[2]?.imageId} mobileImageId={props.details?.[2]?.mobile_image_id} />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default HomeSwiperSection;
