/* eslint-disable @next/next/no-img-element */
/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable jsx-a11y/alt-text */
import { Typography } from '@/components/Atoms';
import ArrowDown3 from '@/components/Svg/ArrowDown3';
import use2DUserData from '@/hooks/Achievements/use2DUserData';
import useAuthStatuses from '@/hooks/authentication/useAuthStatuses';
import { EAuthVarient } from '@/types/Authentication';
import { numberSeparator } from '@/utils/numberSeparator';
import useSwitchAndConnect from '@/utils/useSwitchAndConnect';
import { truncateAddress } from '@/utils/utils';
import { useStore } from '@/zustand/store/store';
import { useTranslate } from '@tolgee/react';
import { default as classNames, default as cn } from 'classnames';
import { useSession } from 'next-auth/react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useAccount } from 'wagmi';
import { Avatar, ConnectToWallet, RewardSvg } from '../../Svg';
import CustomSkeleton from '../CustomSkeleton';
import { MENU_LINKS } from '../Header';
import styles from './MobileMenu.module.scss';

export interface IMobileMenuProps {
  closeMobileMenu: Function;
  Links?: { id: number; title: string; href: string; Icon: any };
}

const MobileMenu = ({ closeMobileMenu }: IMobileMenuProps) => {
  const { t } = useTranslate('header');
  const { isLogin } = useAuthStatuses();
  const [activeLink, setActiveLink] = useState<string | null>(null);
  const [closeAnimation, setCloseAnimation] = useState(false);
  const { setAuthModalVarient, authModalVarient, setIsModalOpen, userData, setOpenConnectLoginOrWalletModal } = useStore();
  const session = useSession();
  const router = useRouter();
  const { isConnected, address } = useAccount();
  const { user2DData: user, isLoading: isUserLoading } = use2DUserData();
  const [hoveredLink, setHoveredLink] = useState(null);
  const [openSubmenuIndex, setOpenSubmenuIndex] = useState<number | null>(null);
  const handleMouseEnter = (index) => {
    setHoveredLink(index);
  };

  const handleMouseLeave = () => {
    setHoveredLink(null);
  };

  useEffect(() => {
    setActiveLink(router.pathname.split('/')[1]);
  }, [router]);

  const handleCloseMenu = () => {
    setCloseAnimation(true);
    setTimeout(() => {
      closeMobileMenu();
    }, 500);
  };

  const clickItem = (item) => {
    if (item?.title?.toLowerCase() === 'inventory' && session.data === null && address == undefined) {
      setOpenConnectLoginOrWalletModal(true);
    } else if (item.title?.toLowerCase() === 'achievement') {
      if (isLogin) {
        router.push(item.href);
        handleCloseMenu();
      } else {
        setAuthModalVarient(EAuthVarient.SIGN_IN, item.href);
      }
    } else if (item.title?.toLowerCase() === 'support') {
      window.open('https://t.me/LandRocker_agent', '_blank', 'noopener,noreferrer');
    } else {
      setActiveLink(item.href);
      router.push(item.href);
      handleCloseMenu();
    }
  };

  const { connect, connectors } = useSwitchAndConnect();

  const handleClickWallet = async () => {
    connect({ connector: connectors[2] });
  };

  const totalPoints = user?.experience || 0;

  const checkActiveLink = (itemHref: string) => {
    const transformedHref = itemHref?.replace(/^\//, '').toLowerCase();

    if (itemHref === '/' && activeLink === '') {
      return true;
    } else {
      return activeLink === transformedHref;
    }
  };

  const isSubLinkActive = (link: string) => router.asPath === link;

  const ActiveTextColor = 'text-[#29d7df]';

  return (
    <div className={cn(`${closeAnimation && styles.closeRoot}`, styles.root)}>
      <div className={'overflow-auto p-4 scrollbar-hide flex flex-col h-full mt-20'}>
        {session.status === 'authenticated' ? (
          // @ts-ignore
          <div className={styles.profile}>
            <Link href='/inventory'>
              <a
                onClick={handleCloseMenu}
                className={cn(styles.profileInfo, 'rounded-lg transition hover:shadow hover:bg-secondary/10 hover:translate-x-4 ps-1')}
              >
                <div className={styles.profilePic}>
                  <Avatar color={'#4bd359'} />
                </div>
                <div className={styles.profileDetails}>
                  <div className={styles.profileUsername}>Hi, {session.data?.user?.name}</div>
                  <div className={styles.profileReferral}>
                    {/* @ts-ignore */}
                    Referral ID: {session.data?.user?.referral}
                  </div>
                </div>
              </a>
            </Link>
            {isUserLoading ? (
              <div className={cn(styles.walletConnected, 'hover:ring-2 ring-secondary rounded-md px-2 py-1')}>
                <div>
                  <RewardSvg isBlack className='h-[1.5em] w-auto' />
                </div>
                <div>
                  <h5 className='flex gap-1'>
                    Total XP: <CustomSkeleton width={'40px'} height='16px' animate color='red' />{' '}
                  </h5>
                </div>
              </div>
            ) : (
              <Link href='/command-center'>
                <a onClick={handleCloseMenu} className={cn(styles.walletConnected, 'hover:ring-2 ring-secondary rounded-md px-2 py-1')}>
                  <div>
                    <RewardSvg isBlack className='h-[1.5em] w-auto' />
                  </div>
                  <div>
                    <h5>Total XP: {numberSeparator(totalPoints, ',')}</h5>
                  </div>
                </a>
              </Link>
            )}

            {address ? (
              <div className={cn(styles.walletConnected, 'px-2')}>
                <div>
                  <ConnectToWallet color='#fff' />
                </div>
                <div>
                  <h5>
                    WALLET {isConnected ? <span className='text-[#4BD359]'>CONNECTED</span> : <span className='text-[#FF0000]'>DISCONNECTED</span>}{' '}
                  </h5>
                  <p> {`${truncateAddress(address)}`}</p>
                </div>
              </div>
            ) : (
              <div className={styles.wallet} onClick={handleClickWallet}>
                <div>
                  <ConnectToWallet color='#29D7DF' />
                </div>
                <span>CONNECT WALLET</span>
              </div>
            )}
          </div>
        ) : (
          <div>
            <span
              onClick={() => {
                closeMobileMenu();
                setAuthModalVarient(EAuthVarient.SIGN_UP);
              }}
            >
              Sign up
            </span>{' '}
            /{' '}
            <span
              onClick={() => {
                closeMobileMenu();
                setAuthModalVarient(EAuthVarient.SIGN_IN);
              }}
            >
              Login
            </span>
          </div>
        )}
        <div>
          {MENU_LINKS.map((item, index) => (
            <div key={index} onMouseLeave={handleMouseLeave}>
              <a
                className={`flex cursor-pointer items-center transition ease-in-out hover:-translate-y-1 hover:scale-180 duration-300 mb-4 px-4 py-1 rounded-md ${
                  checkActiveLink(item.href) ? 'pl-6' : ''
                }`}
                onClick={() => {
                  if (item?.href) {
                    clickItem(item);
                  } else {
                    if (item.subLinks) {
                      setOpenSubmenuIndex(openSubmenuIndex === index ? null : index); // Toggle the submenu
                    }
                  }
                }}
              >
                <div className={`${checkActiveLink(item.href) ? ActiveTextColor : 'white'} font-bold relative flex items-center gap-2`}>
                  <Typography size='s20ToS16'>{t(item.label as any)}</Typography>
                  {item.subLinks && (
                    <div>
                      <ArrowDown3 />
                    </div>
                  )}
                </div>
              </a>
              {openSubmenuIndex === index && item.subLinks && (
                <div className={styles.popover}>
                  {item.subLinks.map((subItem, subIndex) => {
                    const Wrapper = subItem.href ? 'a' : 'div';
                    return (
                      <Wrapper key={subIndex} href={subItem.href} className={classNames(styles.marketplaceItem, 'flex items-center gap-2 mb-1')}>
                        {subItem.icon && (
                          <div
                            style={{
                              backgroundImage: `url(${checkActiveLink(subItem.href) ? '/images/sub-menu-active-bg.png' : '/images/sub-menu-bg.png'})`,
                            }}
                            className='!w-8 !h-8 bg-contain bg-no-repeat bg-center flex items-center justify-center'
                          >
                            <subItem.icon width='16px' height='16px' />
                          </div>
                        )}
                        <div>
                          <Typography
                            size='s14ToS12'
                            className={classNames(styles.subLabel, isSubLinkActive(subItem.href) ? ActiveTextColor : 'text-[#fff]')}
                          >
                            {t(subItem.label as any)}
                          </Typography>
                          <Typography
                            Component={'p'}
                            size='s14ToS12'
                            className={classNames(styles.subDesc, isSubLinkActive(subItem.href) ? ActiveTextColor : 'text-[#fff]')}
                          >
                            {t(subItem.description as any)}
                          </Typography>
                        </div>
                      </Wrapper>
                    );
                  })}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
