import React from 'react';
import { useTranslate } from '@tolgee/react';
import { SocialMediaTokenSale } from '@/components/TokenSale';
import useTranslationStatus from '@/hooks/useTranslationStatus';

const JoinUsLayout = (props: any) => {
  const { t } = useTranslate();
  const { isReady } = useTranslationStatus();

  if (!props) return null;
  console.log(props);
  const getTranslation = (key) => isReady && t(key);
  const data = {
    order: '13',
    title: getTranslation(!props?.title ? 'general.follow_us' : props?.title),
    subTitle: `+300,000 ${getTranslation(!props?.subtitle ? 'general.members' : props?.subtitle)}`,
    discordLink: 'https://discord.com/invite/landrocker',
    twitterLink: 'https://twitter.com/landrocker_io',
    mediumLink: 'https://landrocker.medium.com',
    telegramLink: 'https://t.me/landrockerchat',
    instagramLink: 'https://Instagram.com/landrocker.io',
    followButton: getTranslation(!props?.nameButtonOne ? 'general.follow' : props?.nameButtonOne),
    joinButton: getTranslation(!props?.nameButtonTwo ? 'general.join' : props?.nameButtonTwo),
  };
  return <SocialMediaTokenSale data={data} />;
};

export default JoinUsLayout;
