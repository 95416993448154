import React, { useRef } from 'react';
import { useTranslate } from '@tolgee/react';
import GameSection from '../BattleSection/GameSection';
import { HomeSectionProps } from '../HeroSection';
import { getDirectusImageUrl } from '@/utils/getUrl';

export interface GameModeSectionProps {}

const GameModeSection: React.FunctionComponent<HomeSectionProps> = (props) => {
  const { t } = useTranslate();
  const sectionRef = useRef(null);
  return (
    <div ref={sectionRef} className='px-4 sm:px-7  md:px-10 lg:px-16 xl:px-20 bg-[#000000]'>
      {/* <SectionHeader title='Game Modes' /> */}
      <div className=' md:overflow-x-auto lg:overflow-x-clip md:flex-row flex-col justify-between gap-2 lg:gap-5'>
        <GameSection
          name={t(props.title)}
          hasYGradient
          title={t(props.title)}
          subtitle={t(props.subtitle)}
          description={t(props.description)}
          buttonText={t(props.nameButtonOne)}
          buttonLink={props.linkButtonOne}
          // secondaryButtonText={t('rover.button')}
          // secondaryButtonLink='/lite-game?mode=Play-to-Earn'
          video={getDirectusImageUrl(props.video)}
          buttonTextColor='black'
          weapons={props.details}
          textColor='#47FEFF'
        />
      </div>
    </div>
  );
};

export default GameModeSection;
