import classNames from 'classnames';
import { useRouter } from 'next/router';
import { useTranslate } from '@tolgee/react';
import { CardTokenSale } from '@/components/Home/CardTokenSale';
import { ParseSentence } from '@/utils';
import styles from './CardTokenSaleWrapper.module.scss';

interface ICardTokenSaleWrapper {
  type: 'yellow' | 'green';
  cardsTokenSale: {
    steps: {
      descriptions: string[];
      icon: string;
      title: string;
    }[];
    title: string;
  };
}

const CardTokenSaleWrapper = (props: ICardTokenSaleWrapper) => {
  const { t } = useTranslate('home');
  const { cardsTokenSale, type } = props;
  const parsedTitle = ParseSentence(t('general.how_to_earn_with_lrt'));
  const router = useRouter();
  const currentLanguage = router.locale;

  // const steps = [
  //   {
  //     icon: 'Mine_Rewards_6bff2f68db.svg?6152621.100000024',
  //     title: 'Game Utility',
  //     descriptions: [
  //       'Purchase fuel, rovers, and blueprints.',
  //       'Craft and fuse NFT game assets.',
  //       'Mine high-value rewards like LRT, ETH, and Bitcoin.',
  //     ],
  //   },
  //   {
  //     icon: 'Mine_Rewards_6bff2f68db.svg?6152621.100000024',
  //     title: 'High-Value Rewards',
  //     descriptions: ['Earn LRT, ETH, BTC, and more.', 'Join competitions like the Bitcoin Hunt.', 'Earn weekly leaderboard prizes.'],
  //   },
  //   {
  //     icon: 'Craft_NF_Ts_54f2ce61bb.svg?6161913.800000012',
  //     title: 'Governance',
  //     descriptions: ['Stake for DAO voting rights.', 'Influence game development.'],
  //   },
  //   {
  //     icon: 'Staking_Rewards_a56d2247c3.svg?6181852.300000012',
  //     link: 'https://landrocker.io/stake',
  //     title: 'Staking',
  //     descriptions: ['Lock tokens for passive income.', 'Earn extra LRT, fuel, and rovers.'],
  //   },
  // ];

  // const steps = [
  //   {
  //     descriptions: [
  //       'Power mining missions with Fuel.',
  //       'Collect valuable NFTs, Crypto, Materials, and Blueprints.',
  //       ' Craft & sell NFTs to earn more.',
  //     ],
  //     icon: 'Mine_Rewards_6bff2f68db.svg?6152621.100000024',
  //     title: 'Mine Rewards',
  //   },
  //   {
  //     descriptions: ['Mint valuable NFTs.', 'Use NFTs to increase earnings.', 'Sell NFTs on the Marketplace.'],
  //     icon: 'Craft_NF_Ts_54f2ce61bb.svg?6161913.800000012',
  //     title: 'Craft NFTs',
  //   },
  //   {
  //     descriptions: ['Stake owned Planets for profit share.', 'Gain extra Fuel & upgrade Rovers.', 'Stake in the Liquidity Pool.'],
  //     icon: 'Staking_Rewards_a56d2247c3.svg?6181852.300000012',
  //     link: 'https://landrocker.io/stake',
  //     title: 'Staking Rewards',
  //   },
  // ];

  const titleClass = classNames({
    [styles.title]: currentLanguage === 'en',
    [styles.title_fr]: currentLanguage !== 'en',
  });
  return (
    <div className={styles.wrapper}>
      {type == 'yellow' ? (
        <div className={titleClass}>
          {parsedTitle[0]} <span className='text-[#FBCE00]'>{parsedTitle[1]}</span>
        </div>
      ) : (
        <h2>{t('game_play_overview.game_play_overview')}</h2>
      )}

      <div className={styles.boxCards}>
        {props.cardsTokenSale.steps.map((item, index) => (
          <CardTokenSale key={index} item={item} type={type} />
        ))}
      </div>
    </div>
  );
};

export default CardTokenSaleWrapper;
